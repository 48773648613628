import {useSearchParams} from "react-router-dom";
import {Step, StepConfig} from "../utils/models/config.model";
import {useMemo} from "react";

export const useNavigatorUtil = (
    steps: Step[],
    // @ts-ignore
    currentStepSetup?: StepConfig = null,
    currentStepValue: string | null,
    setCurrentStepKey: (stepKey: string) => void
) => {
    const [params] = useSearchParams();
    // const [previousStep, setPreviousStep] = useState<Step | undefined>(undefined)
    /**
     * @description Funzione che determina quale sia l'id del prossimo step. Per farlo ci sono due modi:
     1) controllare se ci sia nella lista degli step. Questa logica è utile per i configuratori che hanno i vari step standard
     2) controllare se il prossimo(nextStep) step è dichiarato all'interno dell'elemento selezionato*/
    const next = () => {

        if (!currentStepSetup) {
            return;
        }
        let nextStepId: string | undefined;
        if ("elements" in currentStepSetup) {
            nextStepId = currentStepSetup.elements.find(
                (el) => el.id === currentStepValue
            )?.nextStep;
        }
        if (!nextStepId) {
            let nextStepIndex = undefined;
            for (let i = 0; i < steps.length; i++) {
                if (steps[i].id === currentStepSetup.id) {
                    nextStepIndex = i + 1;
                    break;
                }
            }
            if (nextStepIndex) {
                nextStepId = steps[nextStepIndex].id;
            }
        }
        if (!nextStepId) {
            throw new Error("Next step not founded");
        }
        setCurrentStepKey(nextStepId);
    };
    const isFirst = steps?.[0]?.id === currentStepSetup?.id;
    const back = () => {
        if (!currentStepSetup) {
            return;

        }
        let prevStepIndex = undefined;
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].id === currentStepSetup.id) prevStepIndex = i - 1;
        }
        if (prevStepIndex === undefined)
            throw new Error("Previous step not founded");
        setCurrentStepKey(steps[prevStepIndex].id);
        // setPreviousStep(steps[prevStepIndex + 1])
    };
    let title = currentStepSetup?.title || "";
    const regex = /<([a-zA-Z_0-9\-]+)>/g;
    let m;

    while ((m = regex.exec(title)) !== null) {
        title = title.replace(
            `<${m[1]}>`,
            `<strong>${params.get(m[1] + "_title")}</strong>`
        );
        break;
    }

    return useMemo(
        () => ({
            back,
            next,
            title,
            isFirst,
            // previousStep
        }),
        [back, next, title, isFirst /* , previousStep */]
    );
};
