import { useState } from "react";
import { StepConfig } from "../../utils/models/config.model";

export function MaggioriInformazioniMobile({
    stepConfig,
}: {
    stepConfig: StepConfig;
}) {
    const [showInfo, setShowInfo] = useState(false);
    if (!("elements" in stepConfig)) {
        return null;
    }
    switch (stepConfig.template) {
        case 'image-with-text-center-sx':
            break;
        default:
            return null;
    }
    if (
        stepConfig.elements.filter((a) => a.info && a.info.length > 0)
            .length === 0
    ) {
        return null;
    }
    return (
        <div className="block md:hidden mt-12 maggiorni-informazioni">
            <div className="flex flex-col justify-center">
                <h3
                    className="m-c-b-20 m-c-tc-333333"
                    onClick={() => setShowInfo(!showInfo)}
                >
                    Maggiori Informazioni&nbsp;&nbsp;<i className={`arrow ${showInfo ? "up" : "down"}`}></i>
                </h3>
            </div>
            {showInfo && (
                <ol className="mt-6">
                    {stepConfig.elements.map((element) => (
                        <li className="mb-2 text-[12px]">
                            <b>{element.title}:</b> <span>{element.info}</span>
                        </li>
                    ))}
                </ol>
            )}
        </div>
    );
}
