import {ConfiguratorCategoryName} from "../utils/models/configurator.model";

export enum EventIdentifier {
    KitchenConfigurator = "kitchen_configurator",
    SofaConfigurator = "sofa_configurator",
    MattressConfigurator = "mattress_configurator",
}

type TrackingBaseEvent = {
    event: EventIdentifier;
    category: EventIdentifier;
    action: "start" | "end" | "navigation" | string;
    start_point: "menu" | "product_page";
    label?: "summary" | string;
    category_name: ConfiguratorCategoryName;
    product_name: string | null;
    product_id: string | null;
    action_type?: "avanti" | "indietro" | "navbar" | never;
};

type NavigationEvent = TrackingBaseEvent & {
    action: "navigation";
    label: string;
    action_type: "avanti" | "indietro" | "navbar";
};

type StartEvent = TrackingBaseEvent & {
    action: "start";
    label?: never;
    action_type?: never;
};
type EndEvent = TrackingBaseEvent & {
    action: "end";
    label: "summary";
    action_type?: never;
};
type SelectionEvent = TrackingBaseEvent & {
    action: string;
    label: string;
    action_type?: never;
};

export type TrackingEvent =
    | NavigationEvent
    | SelectionEvent
    | StartEvent
    | EndEvent;


export type CallbackFunction = Function;
export type AddToCartConfigurationEventDetail = {
    /** @deprecated */
    sids?: string[];
    /** @deprecated */
    configurationSid?: string[]; //@deprecated
    /** @deprecated */
    sidsToAdd: string[];
    /** @new */
    currency: string;
    source: "configuration_end" | "linked_product"; // @new

    /** @new */
    items_to_add: {
        category: string;
        sid: string;
        name: string;
        price: number;
    }[];
    configuration: {
        rows: {
            description: string;
            price?: number;
            stepKey: string;
            title: string;
        }[];
        sid: string[];
        linkInfo: string;
        total: number;
    };
    callback: CallbackFunction;
};
export type ShowPopUpConfigurationEventDetail = {
    sids?: string[]; // @used?
    source: "configuration_end"; // @new
    // configurationSid?: string[]; //@deprecated
    sidsToAdd: string[]; // @new
    configuration: {
        // @used?
        rows: {
            description: string;
            price?: number;
            stepKey: string;
            title: string;
        }[];
        sid: string[];
        linkInfo: string;
        total: number;
    };
    callback: CallbackFunction;
};

export function getStandardEventObj(
    category: ConfiguratorCategoryName
): Pick<
    TrackingEvent,
    "category" | "start_point" | "product_id" | "product_name" | "category_name"
> {
    switch (category) {
        case ConfiguratorCategoryName.Cucine:
            return {
                category: EventIdentifier.KitchenConfigurator,
                start_point: "menu", //Todo: Non so se sia corretto
                category_name: ConfiguratorCategoryName.Cucine, //Todo: Da aggiungere alle props del componente
                product_name: null, //Todo: Da aggiungere alle props del componente
                product_id: null, //Todo: Da aggiungere alle props del componente
            };
        case ConfiguratorCategoryName.Divani:
            return {
                category: EventIdentifier.SofaConfigurator,
                start_point: "menu", //Todo: Non so se sia corretto
                category_name: ConfiguratorCategoryName.Divani,
                product_name: null, //Todo: Da aggiungere alle props del componente
                product_id: null, //Todo: Da aggiungere alle props del componente
            };
        case ConfiguratorCategoryName.Materassi:
            return {
                category: EventIdentifier.MattressConfigurator,
                start_point: "menu", //Todo: Non so se sia corretto
                category_name: ConfiguratorCategoryName.Materassi,
                product_name: null, //Todo: Da aggiungere alle props del componente
                product_id: null, //Todo: Da aggiungere alle props del componente
            };
    }
}

/**
 * Funzione che restituisce l'evento in base alla categoria
 * @param category
 * @returns
 */
export function getEventIdentifier(
    category: ConfiguratorCategoryName
): EventIdentifier {
    switch (category) {
        case ConfiguratorCategoryName.Cucine:
            return EventIdentifier.KitchenConfigurator;
        case ConfiguratorCategoryName.Divani:
            return EventIdentifier.SofaConfigurator;
        case ConfiguratorCategoryName.Materassi:
            return EventIdentifier.MattressConfigurator;
    }
}

export function raiseEvent<T extends TrackingEvent = TrackingEvent>(
    category: ConfiguratorCategoryName,
    event: Omit<T, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) {
    const trackingEvent = new CustomEvent<TrackingEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                event: getEventIdentifier(category),
                category: getEventIdentifier(category),
                ...(Object.fromEntries(
                    Object.entries(event).filter(([_, v]) => v != null)
                ) as typeof event),
            } as TrackingEvent,
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(trackingEvent);
}

export const raiseNavigationEvent = (
    category: ConfiguratorCategoryName,
    event: Omit<NavigationEvent, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) => raiseEvent(category, event, eventDispatcher);
export const raiseSelectionEvent = (
    category: ConfiguratorCategoryName,
    event: Omit<SelectionEvent, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) =>
    raiseEvent(
        category,
        {
            ...event,
        },
        eventDispatcher
    );
