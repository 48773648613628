import { Element } from "../../utils/models/config.model";

export default function ImageWithTextRight({
    media_base_url,
    element,
    currentStepValue,
    setCurrentStepValue /* setCurrentStepProductTitle */,
}: {
    media_base_url: string;
    element: Element;
    currentStepValue: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void /* setCurrentStepProductTitle: (stepValue: string) => void  */;
}) {
    return (
        <>
            <div
                className={`m-c-card-1${
                    currentStepValue === element.id
                        ? " m-c-card-1-selected"
                        : ""
                } flex items-center pr-[8px] pt-[10px] pl-[8px] md:p-[26px]`}
                style={{ height: "fit-content !important" }}
                onClick={() => {
                    setCurrentStepValue(element.id, element.title);
                }}
            >
                <img src={media_base_url + element.image} />
                <div className="m-c-b-21 m-c-tc-333333 uppercase ml-4 !text-start">
                    {element.title}
                </div>
            </div>
        </>
    );
}
