import {Element, Step, StepConfigDefault} from "../../utils/models/config.model";
import CardImageCenterTitleAndDescCenter from "./CardImageCenterTitleAndDescCenter";
import CardImageCenterTitleAndDescCenterSx from "./CardImageCenterTitleAndDescCenterSx";
import ImageTopTitleAndDescriptionLeftAndFreeMessageAndPriceRight
    from "./ImageTopTitleAndDescriptionLeftAndFreeMessageAndPriceRight";
import ImageTopTitleAndSubtitleLeft from "./ImageTopTitleAndSubtitleLeft";
import ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight
    from "./ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight";
import ImageTopTitleSxLeftAndDescriptionOrPriceRight from "./ImageTopTitleSxLeftAndDescriptionOrPriceRight";
import ImageWithTextRight from "./ImageWithTextRight";
import ImageWithTitleOver from "./ImageWithTitleOver";

export function SingleElement({
                                  media_base_url,
                                  template,
                                  element,
                                  currentStepValue,
                                  step,
                                  setCurrentStepValue,
                                  eventDispatcher,
                              }: {

    media_base_url: string;
    template: string;
    element: Element;
    currentStepValue: string | null;
    step: Step;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string,
    ) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    switch (template) {
        case "image-with-text-center":
            return (
                <CardImageCenterTitleAndDescCenter
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                />
            );
        case "image-with-text-center-sx":
            return (
                <CardImageCenterTitleAndDescCenterSx
                    stepConfig={step as StepConfigDefault}
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                />
            );
        case "image-top-title-and-subtitle-left":
            return (
                <ImageTopTitleAndSubtitleLeft
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />
            );
        case "image-top-title-and-subtitle-left-and-description-or-price-right":
            return (
                <ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />
            );
        case "image-top-title-and-description-left-and-free-message-and-price-right":
            return (
                <ImageTopTitleAndDescriptionLeftAndFreeMessageAndPriceRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />
            );
        case "image-top-title-sx-left-and-description-or-price-right":
            return (
                <ImageTopTitleSxLeftAndDescriptionOrPriceRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />
            );
        case "image-with-text-right":
            return (
                <ImageWithTextRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                />
            );
        case "image-with-title-over":
            return <ImageWithTitleOver
                media_base_url={media_base_url}
                currentStepValue={currentStepValue}
                element={element}
                setCurrentStepValue={setCurrentStepValue}/>;
        // //Questo è un caso speciale... Mondoconvenienza non passa mai questo template.. Ma lo utilizzo io internamento per gestire le opzioni
        // case "option":
        //     return (
        //         <Option
        //             index={index}
        //             steps={steps}
        //             media_base_url={media_base_url}
        //             currentStepValue={currentStepValue}
        //             step={step}
        //             element={element}
        //             setCurrentStepValue={setCurrentStepValue}
        //             eventDispatcher={eventDispatcher}
        //         />
        //     );
        default:
            return <>Template {template} sconosciuto</>;
    }
}
