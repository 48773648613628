import {Element} from "../../utils/models/config.model";

export default function ImageWithTitleOver(
    {
        media_base_url,
        element,
        currentStepValue,
        setCurrentStepValue,
    }: {
        media_base_url: string;
        element: Element;
        currentStepValue: string | null;
        setCurrentStepValue: (
            stepValue: string,
            stepTitle: string,
            product?: string
        ) => void;
    }) {
    return (
        <div
            className={`m-c-card-1-full-image-width-wrapper ${currentStepValue === element.id ? " m-c-card-1-full-image-width-wrapper-selected" : ""}`}
            style={{
                background: `url(${media_base_url + element.image})`,
            }}
        >

            <div
                className={`w-full flex flex-col justify-end`}
                onClick={() => {
                    setCurrentStepValue(element.id, element.title);
                }}
            >
                {/*  <img
                    className={`rounded-[13px] lg:rounded-[35px] !border-none hover:m-[0px] bg-white w-full`}
                    src={media_base_url + element.image}
                />*/}

                <h2 className={`m-c-b-21 ${element.id === "unknown" ? "m-c-tc-333333 " : "text-white image-overlay"} pb-6 md:pb-5 w-full cursor-pointer uppercase`}
                >
                    {element.title}
                </h2>

            </div>
        </div>
    );
}
