import {useCallback, useState} from "react";
import {Product, StepConfigDefault} from "../../utils/models/config.model";
import {fixToPriceWithoutEuroSimbol} from "../../utils/utils";
import ProductInfoModal from "./ProductInfoModal";
import carrello from "./carrello.png";
import check from "./check.png";
/* import migliorPrezzoImg from "./miglior-prezzo.png"; */

export default function CustomProduct({
                                          step, media_base_url,
                                          element,
                                          selected,
                                          setProductSelected /* setCurrentStepProductTitle */,
                                          eventDispatcher,
                                      }: {
    step: StepConfigDefault;
    media_base_url: string;
    element: Product;
    selected: boolean;
    setProductSelected: (sid: string | null, title?: string) => void;
    eventDispatcher: typeof dispatchEvent;
    /* setCurrentStepProductTitle: (stepProductTitle: string) => void  */
}) {
    const innerChangeProduct = () => {
        if (selected) {
            setProductSelected(null);
        } else {
            setProductSelected(element.sid, element.description);
        }
    };
    const [modalOpenStatus, setModalOpenStatus] = useState(false);

    const openModal = useCallback(() => {
        setModalOpenStatus(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalOpenStatus(false);
    }, []);
    let mobileTitle = "";
    let mobileSubtitle = "";
    switch (step.id) {
        case "dishwasher":
            mobileTitle = element.description;
            mobileSubtitle = element.sizes;
            break;
        case "base":
            mobileTitle = element.name;
            mobileSubtitle = `${element.description}\n${element.sizes}`;
            break;
        default:
            mobileTitle = element.description;
            mobileSubtitle = element.sizes
    }

    return (
        <>
            {modalOpenStatus && element.info && (
                <ProductInfoModal
                    html={element.info}
                    onClose={closeModal}
                    labelCta="MAGGIORI INFORMAZIONI"
                    eventDispatcher={eventDispatcher}
                />
            )}
            {/* MOBILE */}
            <div className="flex flex-row md:hidden md:mb-10 w-full">
                <div className="w-[50%] flex flex-col">
                    <img
                        className="flex cursor-pointer"
                        onClick={() => {
                            innerChangeProduct();
                        }}
                        src={media_base_url + element.image}
                    />
                    {!!element.info && (
                        <p
                            onClick={openModal}
                            className="m-c-r-14 !text-[10px] !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                        >
                            MAGGIORI INFORMAZIONI
                        </p>
                    )}
                </div>
                <div className="w-[50%] flex flex-col relative ml-[14px] !pt-[5px]">
                    <div className="flex items-center">
                        <h3 className="m-c-r-25-variant-2 w-[80%] m-c-montserrat !text-start m-c-tc-333333">
                            {mobileTitle}
                        </h3>
                    </div>
                    <p className="m-c-r-16 !leading-[9px] !text-[8px] mt-[8px] !text-start" style={{whiteSpace: "pre"}}>{mobileSubtitle}</p>
                    <div className="flex flex-row-reverse justify-end mt-[8px]">
                        <div className="m-c-discount-wrapper">
                            {element.bestPrice > 0 && (
                                <div className="flex flex-col">
                                    <div className="flex items-end">
                                        <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.bestPrice
                                            )}
                                        </h4>
                                        <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                    {element.discountImage && (
                                        <img
                                            className="miglior-prezzo-image mt-2"
                                            src={
                                                media_base_url +
                                                element.discountImage
                                            }
                                        />
                                    )}
                                </div>
                            )}
                            {(!element.bestPrice || element.bestPrice === 0) &&
                                element.discountPrice &&
                                element.discountPrice &&
                                element.discountPrice !== 0 && (
                                    <>
                                        <div className="">
                                            <h4 className="ml-1.5 m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                                {fixToPriceWithoutEuroSimbol(
                                                    element.price
                                                )}
                                            </h4>
                                            <h4 className="ml-1.5 m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                                €
                                            </h4>
                                        </div>
                                        <div className="absolute z-50 m-c-discount-line"></div>
                                    </>
                                )}
                        </div>
                        {(!element.bestPrice || element.bestPrice === 0) && (
                            <div className="flex items-end">
                                <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                    {element.discountPrice &&
                                    element.discountPrice !== 0
                                        ? fixToPriceWithoutEuroSimbol(
                                            element.discountPrice
                                        )
                                        : fixToPriceWithoutEuroSimbol(
                                            element.price
                                        )}
                                </h4>
                                <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                    €
                                </h4>
                            </div>
                        )}
                    </div>

                    <button
                        className={`m-c-button${
                            selected ? "-selected" : ""
                        } m-c-full-rounded-button m-c-b-16 m-c-tc-FFFFFF mt-3 absolute bottom-[14px] right-[9px]`}
                        onClick={() => {
                            innerChangeProduct();
                        }}
                    >
                        <div className="m-c-content-wrapper">
                            {selected && <img src={check}/>}
                            {!selected && <img src={carrello}/>}
                        </div>
                    </button>
                </div>
            </div>
            {/* DESKTOP */}
            <div className="hidden md:flex md:flex-col md:mb-10">
                <img
                    className="flex cursor-pointer"
                    onClick={() => {
                        innerChangeProduct();
                    }}
                    src={media_base_url + element.image}
                />
                <div className="flex justify-between mt-4">
                    <div className="hidden md:flex items-center">
                        <h3 className="m-c-r-25-variant-2 !text-start m-c-tc-333333">
                            {element.name}
                        </h3>
                    </div>
                    <div className="flex md:hidden items-center">
                        <h3 className="m-c-r-25-variant-2 m-c-montserrat !text-start m-c-tc-333333">
                            {element.description}
                        </h3>
                    </div>
                    <div className="hidden md:flex md:items-end md:justify-end xl:min-w-[55%]">
                        <div className="m-c-discount-wrapper">
                            {element.bestPrice > 0 && (
                                <div className="flex flex-col-reverse xl:flex xl:flex-row xl:items-center">
                                    {element.discountImage && (
                                        <img
                                            className="miglior-prezzo-image xl:mr-3 mt-1 xl:mt-0"
                                            src={
                                                media_base_url +
                                                element.discountImage
                                            }
                                        />
                                    )}
                                    <div className="flex items-end justify-end">
                                        <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.bestPrice
                                            )}
                                        </h4>
                                        <h4 className="m-c-b-16-variant m-c-tc-333333  !text-end">
                                            €
                                        </h4>
                                    </div>
                                </div>
                            )}
                            {(!element.bestPrice || element.bestPrice === 0) &&
                                element.discountPrice && (
                                    <>
                                        <div className="flex items-end">
                                            <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                                {fixToPriceWithoutEuroSimbol(
                                                    element.price
                                                )}
                                            </h4>
                                            <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                                €
                                            </h4>
                                        </div>
                                        <div className="absolute z-50 m-c-discount-line"></div>
                                    </>
                                )}
                            {(!element.bestPrice ||
                                element.bestPrice === 0) && (
                                <div className="flex items-end">
                                    <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                        {element.discountPrice &&
                                        element.discountPrice !== 0
                                            ? fixToPriceWithoutEuroSimbol(
                                                element.discountPrice
                                            )
                                            : fixToPriceWithoutEuroSimbol(
                                                element.price
                                            )}
                                    </h4>
                                    <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                    <p className="hidden md:flex m-c-r-16 !text-start max-w-[50%] m-c-tc-333333">
                        {element.description}
                    </p>
                    <p className="m-c-r-16 m-c-tc-333333 !text-end">
                        {element.sizes}
                    </p>
                </div>
                <div className="flex flex-row-reverse justify-end md:hidden">
                    <div className="m-c-discount-wrapper">
                        {element.bestPrice > 0 && (
                            <div className="flex items-center">
                                <div className="flex items-end">
                                    <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                        {fixToPriceWithoutEuroSimbol(
                                            element.bestPrice
                                        )}
                                    </h4>
                                    <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                                {element.discountImage && (
                                    <img
                                        className="miglior-prezzo-image ml-3"
                                        src={
                                            media_base_url +
                                            element.discountImage
                                        }
                                    />
                                )}
                            </div>
                        )}
                        {(!element.bestPrice || element.bestPrice === 0) &&
                            element.discountPrice &&
                            element.discountPrice &&
                            element.discountPrice !== 0 && (
                                <>
                                    <div className="">
                                        <h4 className="ml-1.5 m-c-b-22-variant m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.price
                                            )}
                                        </h4>
                                        <h4 className="ml-1.5 m-c-b-16-variant m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                    <div className="absolute z-50 m-c-discount-line"></div>
                                </>
                            )}
                    </div>
                    {(!element.bestPrice || element.bestPrice === 0) && (
                        <div className="flex items-end">
                            <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                {element.discountPrice &&
                                element.discountPrice !== 0
                                    ? fixToPriceWithoutEuroSimbol(
                                        element.discountPrice
                                    )
                                    : fixToPriceWithoutEuroSimbol(
                                        element.price
                                    )}
                            </h4>
                            <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                €
                            </h4>
                        </div>
                    )}
                </div>
                {!!element.info && (
                    <p
                        onClick={openModal}
                        className="m-c-r-14 !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                    >
                        MAGGIORI INFORMAZIONI
                    </p>
                )}
                <button
                    className={`m-c-button${
                        selected ? "-selected" : ""
                    } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF mt-3 !w-full`}
                    onClick={() => {
                        innerChangeProduct();
                    }}
                >
                    <div className="m-c-content-wrapper">
                        {selected ? "AGGIUNTO" : "AGGIUNGI"}
                    </div>
                </button>
            </div>
        </>
    );
}
