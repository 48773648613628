import { ConfiguratorCategoryName } from "../utils/models/configurator.model";

/** @deprecated */
type BaseEvent = {
    event: string;
    category: "kitchen_configurator";
    action: string;
    start_point: "menu" | "product_page";
    label: string;
    category_name: string;
    product_name: string | null;
    product_id: string | null;
};
export type SelectionWithNavigationEvent = BaseEvent;
/** @deprecated */
export function selectionWithNavigation(
    event: SelectionWithNavigationEvent,
    eventDispatcher: typeof dispatchEvent
) {
    const eveventObj = event;
    const selectionWithNavigationEvent =
        new CustomEvent<SelectionWithNavigationEvent>(
            "decision-tree-tag-manager",
            {
                detail: {
                    ...(Object.fromEntries(
                        Object.entries(eveventObj).filter(([_, v]) => v != null)
                    ) as SelectionWithNavigationEvent),
                },
                bubbles: true,
                cancelable: true,
                composed: false,
            }
        );
    eventDispatcher(selectionWithNavigationEvent);
}
export type SimpleNavigationEvent = BaseEvent & {
    action_type: "avanti" | "indietro";
};
/** @deprecated */
export function simpleNavigation(
    event: SimpleNavigationEvent,
    eventDispatcher: typeof dispatchEvent
) {
    const eveventObj = event;
    const simpleNavigationEvent = new CustomEvent<SimpleNavigationEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                ...(Object.fromEntries(
                    Object.entries(eveventObj).filter(([_, v]) => v != null)
                ) as SimpleNavigationEvent),
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(simpleNavigationEvent);
}
export function modalOpened(
    event: SimpleNavigationEvent,
    eventDispatcher: typeof dispatchEvent
) {
    const eveventObj = event;
    const simpleNavigationEvent = new CustomEvent<SimpleNavigationEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                ...(Object.fromEntries(
                    Object.entries(eveventObj).filter(([_, v]) => v != null)
                ) as SimpleNavigationEvent),
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(simpleNavigationEvent);
}
/** @deprecated */
export function endPageLanding(
    category_name: BaseEvent["category_name"],
    product_name: BaseEvent["product_name"] = null,
    product_id: BaseEvent["product_id"] = null,
    eventDispatcher: typeof dispatchEvent
) {
    const eveventObj: BaseEvent = {
        event: "kitchen_configurator_end", //Todo: Da aggiungere alle props del componente
        category: "kitchen_configurator", //Todo: Da aggiungere alle props del componente
        action: "end",
        start_point: "menu",
        label: "summary",
        product_name,
        category_name,
        product_id,
    };
    const simpleNavigationEvent = new CustomEvent<BaseEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                ...(Object.fromEntries(
                    Object.entries(eveventObj).filter(([_, v]) => v != null)
                ) as BaseEvent),
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(simpleNavigationEvent);
}
type CtaInteractionEvent = {
    event: "click_cta";
    category: "click";
    action: "cta_global";
    label: string;
};
export function ctaInteraction(
    ctaEvent: CtaInteractionEvent,
    eventDispatcher: typeof dispatchEvent
) {
    const ctaInteractionEvent = new CustomEvent<CtaInteractionEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                ...(Object.fromEntries(
                    Object.entries(ctaEvent).filter(([_, v]) => v != null)
                ) as CtaInteractionEvent),
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(ctaInteractionEvent);
}
export type OptionSelectionEvent = BaseEvent;
/** @deprecated */
export function optionSelectedEvent(
    event: OptionSelectionEvent,
    eventDispatcher: typeof dispatchEvent
) {
    const eveventObj = event;
    const optionSelectedEvent = new CustomEvent<OptionSelectionEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                ...(Object.fromEntries(
                    Object.entries(eveventObj).filter(([_, v]) => v != null)
                ) as OptionSelectionEvent),
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(optionSelectedEvent);
}
/** @deprecated */
export function init(eventDispatcher: typeof dispatchEvent, userData?: any) {
    const data: any = {
        detail: {
            event: "init",
            logged_in: !!userData?.userId || !!userData?.user_id,
            pageName: window.location.pathname,
            pageType: "configurator",
        },
        bubbles: true,
        cancelable: true,
        composed: false,
    };
    if (userData) {
        data.detail.userData = userData;
    }
    const initEvent = new CustomEvent<any>("decision-tree-tag-manager", data);
    eventDispatcher(initEvent);
}

type CtaSchedaProdottoEvent = {
    event: "select_item";
    action_type: "configurator"; // oppure “type” o “proposals” o “configurator”
    ecommerce: {
        items: [
            {
                item_id: string;
                item_name?: string;
                affiliation: "store online";
                currency: "EUR";
                index: number;
                price: number;
                discount?: number;
                quantity: 1;
                item_category: ConfiguratorCategoryName;
            }
        ];
    };
};
export function schedaProdotto(
    ctaEvent: CtaSchedaProdottoEvent["ecommerce"]["items"][0],
    eventDispatcher: typeof dispatchEvent
) {
    const ctaInteractionEvent = new CustomEvent<CtaSchedaProdottoEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                event: "select_item",
                action_type: "configurator", // oppure “type” o “proposals” o “configurator”
                ecommerce: {
                    items: [ctaEvent],
                },
            },
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(ctaInteractionEvent);
}
