import {Element, StepConfigDefault} from "../../utils/models/config.model";
import informationButton from "./information-button.png";

export default function CardImageCenterTitleAndDescCenterSx({
                                                                stepConfig,
                                                                media_base_url,
                                                                element,
                                                                currentStepValue,
                                                                setCurrentStepValue,
                                                            }: {
    stepConfig: StepConfigDefault;
    media_base_url: string;
    element: Element;
    currentStepValue: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string,
    ) => void;
}) {
    return (
        <div
            key={element.id}
            className={`m-c-card-1${
                currentStepValue === element.id ? " m-c-card-1-selected" : ""
            } flex flex-col justify-center items-center p-6 md:pb-5 md:px-16 max-h-[360px]`}
            onClick={() => {
                setCurrentStepValue(element.id, element.title);
            }}
        >
            {element.image && (
                <img
                    className="!max-w-full mc-card-image"
                    src={media_base_url + element.image}
                />
            )}
            <div className="flex mt-5">
                <h2 className={`m-c-b-21 m-c-tc-333333 ${stepConfig.preserveTitleCase === true ? "" : "uppercase"}`}>
                    {element.title}
                </h2>
                {element.info && (
                    <div className="m-c-info-icon-wrapper !ml-3">
                        <img
                            className="m-c-info-icon max-w-none"
                            src={informationButton}
                        />
                        <p className="m-c-m-12 m-c-tc-333333">{element.info}</p>
                    </div>
                )}
            </div>
            <h3 className="m-c-b-21 m-c-tc-333333">{element.subtitle}</h3>
            {element.description && (
                <p className="m-c-r-14 m-c-tc-333333 mt-2">
                    {element.description}
                </p>
            )}
        </div>
    );
}
