import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigatorUtil } from "../hooks/useNavigatorUtil";
import { Step, StepConfig, StepExtraEnd } from "../utils/models/config.model";
import { schedaProdotto } from "../services/events";
import { ConfiguratorCategoryName } from "../utils/models/configurator.model";
import LinkedProduct from "./Shared/LinkedProduct";
import { AddToCartConfigurationEventDetail } from "../services/eventsV2";

export default function BottomNavigator({
    configurator_category_name,
    steps,
    currentStepSetup,
    currentStepValue,
    media_base_url,
    setCurrentStepKey,
    handleVisibilityChange,
    eventDispatcher,
}: {
    configurator_category_name: ConfiguratorCategoryName;
    steps: Step[];
    currentStepSetup: StepConfig;
    currentStepValue: string | null;
    media_base_url: string;
    setCurrentStepKey: (stepKey: string) => void;
    handleVisibilityChange: (value: boolean) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { isFirst, back, next } = useNavigatorUtil(
        steps,
        currentStepSetup,
        currentStepValue,
        setCurrentStepKey
    );
    useEffect(() => {
        const io = new IntersectionObserver(
            (el) => {
                const change = el[0];
                setIsVisible(change.isIntersecting);
            },
            {
                // root: titleReference.current?.ownerDocument,
                threshold: 0.5,
            }
        );
        io.observe(ref.current!);
        return () => io.disconnect();
    }, []);
    useEffect(() => {
        handleVisibilityChange(isVisible);
    }, [handleVisibilityChange, isVisible]);
    const notifyClickSchedaProdotto = () => {
        schedaProdotto(
            {
                item_id: currentStepSetup.stepExtra.sid[0], // FIXME, quale sid?
                price: currentStepSetup.stepExtra.total, // FIXME quale è il prezzo?
                affiliation: "store online",
                currency: "EUR",
                index: 0,
                item_category: configurator_category_name,
                quantity: 1,
            },
            eventDispatcher
        );
    };
    const callback = useCallback(
        (e: { success: boolean; message?: string }) => {
            if (!e.success) {
                setLoading(false);
            }
            console.log(
                `success: ${e.success} message: ${
                    e.message
                } Event: ${JSON.stringify(e)}`
            );
        },
        []
    );

    const aggiungiAlCarrelloProdottoCollegato = (sid: string) => {
        setLoading(true);
        let price = null;
        const linkedProduct =
            currentStepSetup.stepExtra.linkedProducts.find(
                (p) => p.sid === sid
            )!;
        if (linkedProduct.bestPrice) {
            price = linkedProduct.bestPrice;
        } else if (linkedProduct.discountPrice) {
            price = linkedProduct.discountPrice;
        } else {
            price = linkedProduct.price;
        }
        const addToCartEvent =
            new CustomEvent<AddToCartConfigurationEventDetail>(
                "decision-tree-add-to-cart",
                {
                    detail: {
                        items_to_add: [
                            {
                                category: linkedProduct.category,
                                name: linkedProduct.name,
                                price: price!,
                                sid: sid,
                            },
                        ],
                        currency: "EUR",
                        source: "linked_product",
                        configurationSid: [sid],
                        sidsToAdd: [sid],
                        callback: callback,
                        configuration: {
                            ...(currentStepSetup.stepExtra as unknown as StepExtraEnd),
                            linkInfo: (
                                currentStepSetup.stepExtra as unknown as StepExtraEnd
                            ).linkInfos.url,
                        },
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                }
            );
        eventDispatcher(addToCartEvent);
    };
    return (
        <>
            <div ref={ref} style={{ marginBottom: 50 }}>
                {currentStepSetup.id !== "end" && (
                    <div
                        className={`flex ${
                            isFirst ? "justify-end" : "justify-between"
                        } pt-8`}
                    >
                        <div className={``}>
                            {!isFirst && (
                                <button
                                    className="m-c-button-variant m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF"
                                    onClick={() => {
                                        back();
                                    }}
                                >
                                    <div className="m-c-content-wrapper">
                                        <div
                                            className="m-c-button-triangle-left"
                                            style={{ marginRight: 9.13 }}
                                        ></div>
                                        Indietro
                                    </div>
                                </button>
                            )}
                        </div>
                        <div className={``}>
                            <button
                                className={`m-c-button${
                                    currentStepSetup?.required &&
                                    !currentStepValue
                                        ? "-disabled"
                                        : ""
                                } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF`}
                                disabled={
                                    currentStepSetup?.required &&
                                    !currentStepValue
                                }
                                onClick={() => {
                                    next();
                                }}
                            >
                                <div className="m-c-content-wrapper">
                                    Avanti
                                    <div
                                        className="m-c-button-triangle-right"
                                        style={{ marginLeft: 9.13 }}
                                    ></div>
                                </div>
                            </button>
                        </div>
                    </div>
                )}
                {currentStepSetup.id === "end" && (
                    <>
                        <div className="md:hidden mt-4">
                            <div
                                className={`flex justify-${
                                    currentStepSetup.stepExtra.linkInfos.url
                                        ? "between"
                                        : "start"
                                } m-c-b-21 m-c-tc-1C2F66`}
                            >
                                <div
                                    onClick={() => {
                                        back();
                                    }}
                                    className="flex items-center cursor-pointer"
                                >
                                    <div
                                        className="m-c-button-triangle-left"
                                        style={{ marginRight: 9.13 }}
                                    ></div>
                                    <div className="underline">Indietro</div>
                                </div>
                                {currentStepSetup.stepExtra.linkInfos.url && (
                                    <a
                                        href={
                                            currentStepSetup.stepExtra.linkInfos
                                                .url
                                        }
                                        onClick={() =>
                                            notifyClickSchedaProdotto()
                                        }
                                        target="_blank"
                                        className="flex items-center cursor-pointer"
                                    >
                                        <div className="underline">
                                            Vai alla scheda prodotto
                                        </div>
                                    </a>
                                )}
                            </div>
                        </div>
                        {currentStepSetup?.stepExtra?.linkedProducts &&
                            currentStepSetup?.stepExtra?.linkedProducts.length >
                                0 && (
                                <div className="md:hidden m-c-end-linked-products-wrapper">
                                    <div
                                        className="m-c-m-25"
                                        style={{ lineHeight: "23px" }}
                                    >
                                        {
                                            currentStepSetup.stepExtra
                                                .linkedProductsTitle
                                        }
                                    </div>
                                    <div
                                        className={`m-c-end-linked-products-list-wrapper grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[25px] xl:gap-[20px]`}
                                    >
                                        {currentStepSetup.stepExtra.linkedProducts.map(
                                            (product) => {
                                                return (
                                                    <LinkedProduct
                                                        addToCartEventPending={
                                                            loading
                                                        }
                                                        element={product}
                                                        eventDispatcher={
                                                            eventDispatcher
                                                        }
                                                        media_base_url={
                                                            media_base_url
                                                        }
                                                        selected={false}
                                                        key={product.sid}
                                                        addToCart={
                                                            aggiungiAlCarrelloProdottoCollegato
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                    </>
                )}
            </div>
        </>
    );
}
