import { useEffect, useRef, useState } from "react";
import { useNavigatorUtil } from "../hooks/useNavigatorUtil";
import {
    Step,
    StepConfigDefault,
    StepConfigEnd,
} from "../utils/models/config.model";

export function FixedNavigator({
    stepConfig,
    steps,
    setCurrentStepKey,
    currentStepValue,
}: {
    stepConfig: StepConfigDefault | StepConfigEnd;
    steps: Step[];
    setCurrentStepKey: (stepKey: string) => void;
    currentStepValue: string | null;
}) {
    const { next, back, isFirst } = useNavigatorUtil(
        steps,
        stepConfig,
        currentStepValue,
        setCurrentStepKey
    );
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(ref.current?.clientWidth || 0);
    }, [ref]);
    useEffect(() => {
        const handleResize = (event: any) => {
            /* setScrollTop(window.scrollY); */
            if (ref.current?.clientWidth !== width) {
                setWidth(ref.current?.clientWidth || 0);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div ref={ref} className="floating-navigator">
            <div className="fixed w-full left-0">
                <div className="container mx-auto ">
                    <div className={`p-8 md:p-0 flex justify-center`}>
                        {!isFirst && (
                            <button
                                className="m-c-button-variant m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF mr-1"
                                onClick={back}
                            >
                                <div className="m-c-content-wrapper">
                                    <div className="m-c-button-triangle-left"></div>
                                    Indietro
                                </div>
                            </button>
                        )}
                        <div className="flex justify-end">
                            <button
                                className={`m-c-button${
                                    stepConfig?.required && !currentStepValue
                                        ? "-disabled"
                                        : ""
                                } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF ${
                                    !isFirst ? "ml-1" : "ml-[182px]"
                                }`}
                                disabled={
                                    stepConfig?.required && !currentStepValue
                                }
                                onClick={next}
                            >
                                <div className="m-c-content-wrapper">
                                    Avanti
                                    <div
                                        className="m-c-button-triangle-right"
                                        style={{ marginLeft: 9.13 }}
                                    ></div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
