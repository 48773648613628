import {useCallback, useEffect, useState} from "react";
import {
    AddToCartConfigurationEventDetail,
    getStandardEventObj,
    raiseEvent,
    ShowPopUpConfigurationEventDetail,
} from "../services/eventsV2";
import {StepExtraEnd} from "../utils/models/config.model";
import {ConfiguratorCategoryName} from "../utils/models/configurator.model";
import {fixToPriceWithoutEuroSimbol} from "../utils/utils";
import LinkedProduct from "./Shared/LinkedProduct";
import alert from "./Shared/alert.png";

export default function End({
                                configurator_category_name,
                                media_base_url,
                                endConfig,
                                eventDispatcher,
                            }: {
    configurator_category_name: ConfiguratorCategoryName;
    media_base_url: string;
    endConfig: StepExtraEnd;
    eventDispatcher: typeof dispatchEvent;
}) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        raiseEvent(
            configurator_category_name,
            {
                ...getStandardEventObj(configurator_category_name),
                action: "end",
                label: "summary",
            },
            eventDispatcher
        ); //Todo: Levare questi 'null' in quanto dipendono dal fatto che il componente non deriva dalla pagina prodotto
    }, []);
    const callback = useCallback(
        (e: { success: boolean; message?: string }) => {
            if (!e.success) {
                setLoading(false);
            }
            console.log(
                `success: ${e.success} message: ${
                    e.message
                } Event: ${JSON.stringify(e)}`
            );
        },
        []
    );
    const aggiungiAlCarrelloProdottoCollegato = (sid: string) => {
        setLoading(true);
        const linkedProduct = endConfig.linkedProducts.find(
            (p) => p.sid === sid
        )!;
        let price = linkedProduct.bestPrice || linkedProduct.discountPrice || linkedProduct.price;
        if (price === null) {
            throw new Error("Price not found");
        }
        const addToCartEvent =
            new CustomEvent<AddToCartConfigurationEventDetail>(
                "decision-tree-add-to-cart",
                {
                    detail: {
                        items_to_add: [
                            {
                                category: linkedProduct.category,
                                name: linkedProduct.name,
                                price: price!,
                                sid,
                            },
                        ],
                        currency: "EUR",
                        source: "linked_product",
                        // configurationSid: [sid],
                        sidsToAdd: [sid],
                        callback: callback,
                        configuration: {
                            ...endConfig,
                            linkInfo: endConfig.linkInfos.url,
                        },
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                }
            );
        eventDispatcher(addToCartEvent);
    };
    const aggiungiAlCarrelloConfigurazione = () => {
        setLoading(true);

        const sids = endConfig.rows.map((el) => {
            return el.stepKey;
        });
        const items_to_add = endConfig.endProducts.map((el) => {
            const price = el.bestPrice || el.discountPrice || el.price;
            if (price === null) {
                throw new Error("Price not found");
            }

            return {
                category: el.category,
                name: el.name,
                price: price,
                sid: el.sid,
            }
        });

        const addToCartEvent =
            new CustomEvent<AddToCartConfigurationEventDetail>(
                "decision-tree-add-to-cart",
                {
                    detail: {
                        sids,
                        items_to_add,
                        currency: "EUR",
                        source: "configuration_end",
                        sidsToAdd: endConfig.sid,
                        callback: callback,
                        configuration: {
                            ...endConfig,
                            linkInfo: endConfig.linkInfos.url,
                        },
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                }
            );
        eventDispatcher(addToCartEvent);
    };
    const showPopUpLineeGuidaCallback = useCallback(
        (e: { success: boolean; message?: string }) => {
            console.log(e);
        },
        []
    );
    const showPopUpLineeGuida = () => {
        const sids = endConfig.rows.map((el) => {
            return el.stepKey;
        });

        const showPopUpLineeGuidaEvent =
            new CustomEvent<ShowPopUpConfigurationEventDetail>(
                "decision-tree-show-alert",
                {
                    detail: {
                        sids,
                        source: "configuration_end",
                        sidsToAdd: endConfig.sid,
                        // configurationSid: endConfig.sid,
                        callback: showPopUpLineeGuidaCallback,
                        configuration: {
                            ...endConfig,
                            linkInfo: endConfig.linkInfos.url,
                        },
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                }
            );
        eventDispatcher(showPopUpLineeGuidaEvent);
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <img
                    src={`${media_base_url + endConfig?.image}`}
                    style={{width: "100%"}}
                />
                <div className="flex flex-col mb-0 md:ml-5">
                    {endConfig.rows.map((el, index) => {
                        return (
                            <div key={el.stepKey}>
                                <div className="flex justify-between">
                                    <div className="m-c-sb-16 m-c-tc-333333">
                                        {el.title}:
                                    </div>
                                    {!el?.price && (
                                        <div className="m-c-r-16-end m-c-tc-333333 !text-end">
                                            {el.description}
                                        </div>
                                    )}
                                    {!!el?.price && el?.price > 0 && (
                                        <div
                                            className="flex flex-col items-end md:flex md:flex-row md:items-center md:justify-end">
                                            <div className="m-c-r-16-end !text-end">
                                                {el.description}
                                            </div>
                                            {el?.price !== null && (
                                                <div className="m-c-b-21-variant m-c-tc-333333 ml-2 text-wrap-no-wrap">
                                                    + {el.price} €
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="m-c-line mb-4 mt-4"></div>
                            </div>
                        );
                    })}
                    <div className="mt-4 xl:mt-6">
                        <div className="flex justify-between">
                            <div className="flex flex-col justify-end">
                                <div className="m-c-sb-21 m-c-totale m-c-tc-333333">
                                    Totale:
                                </div>
                            </div>
                            <div className="flex"></div>
                            <div className="flex items-end">
                                <div className="m-c-b-50-variant m-c-tc-333333">
                                    {fixToPriceWithoutEuroSimbol(
                                        endConfig.total
                                    )}
                                </div>
                                <div className="m-c-tc-333333 m-c-b-28-variant-end">
                                    €
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-8 md:mb-4">
                        <button
                            disabled={loading}
                            className={`m-c-button${
                                loading === true ? "-disabled" : ""
                            } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF !w-full`}
                            onClick={() => {
                                aggiungiAlCarrelloConfigurazione();
                            }}
                        >
                            <div className="flex items-center">
                                <div className="m-c-cart-icon">
                                    <svg
                                        className="color-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24"
                                        viewBox="0 -960 960 960"
                                        width="24"
                                    >
                                        <path
                                            d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"/>
                                    </svg>
                                </div>
                                <div className="m-c-content-wrapper">
                                    Aggiungi al carrello
                                </div>
                            </div>
                        </button>
                    </div>
                    {endConfig.extraInfoLinks &&
                        endConfig?.extraInfoLinks?.length > 0 && (
                            <div className="mt-9 mb-3 2xl:mb-0">
                                <div className="m-c-b-21 m-c-tc-333333 !text-[16px] md:!text-[21px] !text-start">
                                    Maggiori informazioni
                                </div>
                                {configurator_category_name ===
                                    ConfiguratorCategoryName.Cucine && (
                                        <button
                                            type="button"
                                            className="flex items-center p-0 mt-[16px]"
                                            onClick={() => {
                                                showPopUpLineeGuida();
                                            }}
                                        >
                                            <img
                                                src={alert}
                                                className="mr-2"
                                                alt="alert-lineaa-guida"
                                            />
                                            <div
                                                className="m-c-r-16 !text-[12px] md:!text-[16px] m-c-tc-333333 underline">
                                                Leggi le{" "}
                                                <strong>linee guida</strong> per la
                                                consegna e il montaggio
                                            </div>
                                        </button>
                                    )}
                                <div
                                    className="flex flex-col 2xl:flex 2xl:flex-row 2xl:flex-wrap 2xl:gap-y-6 mt-0 2xl:mt-6">
                                    {endConfig?.extraInfoLinks?.map((el) => {
                                        return (
                                            <a
                                                href={`${
                                                    media_base_url + el.url
                                                }`}
                                                target="_blank"
                                                key={el.url}
                                                className="mr-[30px] mt-[18px] 2xl:mt-0"
                                            >
                                                <div className="flex items-center">
                                                    <div
                                                        className="m-c-b-16 m-c-tc-333333 !text-[14px] md:!text-[16px] underline uppercase !text-start"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {el.name}
                                                    </div>
                                                    <div className="ml-2">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path
                                                                id="Icon_material-download-for-offline"
                                                                data-name="Icon material-download-for-offline"
                                                                d="M13,3A10,10,0,1,0,23,13,10.016,10.016,0,0,0,13,3Zm-1,8V7h2v4h3l-4,4L9,11Zm6,7H8V16H18Z"
                                                                transform="translate(-3 -3)"
                                                                fill="#152c69"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    })}
                                </div>
                                {endConfig?.extraInfoMessage && (
                                    <div className="m-c-r-14-variant m-c-tc-333333 !font-regular !text-start mt-6">
                                        {endConfig.extraInfoMessage}
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </div>

            {endConfig.linkedProducts &&
                endConfig.linkedProducts.length > 0 && (
                    <div className="hidden md:block  m-c-end-linked-products-wrapper">
                        {endConfig.linkedProductsTitle &&
                            endConfig.linkedProductsTitle !== "" && (
                                <div className="m-c-b-25">
                                    {endConfig.linkedProductsTitle}
                                </div>
                            )}
                        <div
                            className={`m-c-end-linked-products-list-wrapper grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[25px] xl:gap-[20px]`}
                        >
                            {endConfig.linkedProducts.map((product) => {
                                return (
                                    <LinkedProduct
                                        addToCartEventPending={loading}
                                        element={product}
                                        eventDispatcher={eventDispatcher}
                                        media_base_url={media_base_url}
                                        selected={false}
                                        key={product.sid}
                                        addToCart={
                                            aggiungiAlCarrelloProdottoCollegato
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
        </>
    );
}
