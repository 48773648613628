import {Configurazione, Step, StepConfigDefault, StepExtraEnd,} from "../utils/models/config.model";
import {ConfiguratorCategoryName} from "../utils/models/configurator.model";
import DefaultStep from "./DefaultStep";
import MultiChoiceStep from "./MultiChoiceStep";
import End from "./End";

export default function SingleStep({
                                       configurator_category_name,
                                       steps,
                                       media_base_url,
                                       stepConfig,
                                       stepExtra,
                                       currentStepValue,
                                       currentStepProduct,
                                       setCurrentStepValue,
                                       setCurrentStepKey,
                                       eventDispatcher /* setCurrentStepProductTitle */,
                                   }: {
    configurator_category_name: ConfiguratorCategoryName;
    steps: Step[];
    media_base_url: string;
    stepConfig: Configurazione["stepConfig"];
    stepExtra?: StepExtraEnd;
    currentStepValue: string | null;
    currentStepProduct: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void;
    setCurrentStepKey: (stepKey: string) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    switch (stepConfig.id) {
        case "end":
            return (
                <End
                    configurator_category_name={configurator_category_name}
                    media_base_url={media_base_url}
                    endConfig={stepExtra as StepExtraEnd}
                    eventDispatcher={eventDispatcher}
                ></End>
            );
        case "dishwasher":
        case "base":
            return (
                <MultiChoiceStep
                    steps={steps}
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    currentStepProduct={currentStepProduct}
                    setCurrentStepValue={setCurrentStepValue}
                    step={stepConfig as StepConfigDefault}
                    key={stepConfig.id}
                    eventDispatcher={eventDispatcher}
                />
            );
        default:
            return (
                <DefaultStep
                    configurator_category_name={configurator_category_name}
                    steps={steps}
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    currentStepProduct={currentStepProduct}
                    setCurrentStepValue={setCurrentStepValue}
                    step={stepConfig as StepConfigDefault}
                    key={stepConfig.id}
                    eventDispatcher={eventDispatcher}
                />
            );
    }
}
