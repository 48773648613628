import {useCallback, useState} from "react";
import {LinkedProduct as LinkedProductType} from "../../utils/models/config.model";
import {fixToPriceWithoutEuroSimbol} from "../../utils/utils";
import ProductInfoModal from "./ProductInfoModal";
import carrello from "./carrello.png";
import check from "./check.png";
/* import migliorPrezzoImg from "./miglior-prezzo.png"; */

export default function LinkedProducts({
                                           media_base_url,
                                           element,
                                           selected,
                                           /* setProductSelected, */
                                           addToCartEventPending,
                                           addToCart,
                                           eventDispatcher,
                                       }: {
    media_base_url: string;
    element: LinkedProductType;
    addToCart: (sid: string) => void;
    selected: boolean;
    addToCartEventPending: boolean;
    eventDispatcher: typeof dispatchEvent;
}) {
    const [modalOpenStatus, setModalOpenStatus] = useState(false);

    const openModal = useCallback(() => {
        setModalOpenStatus(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalOpenStatus(false);
    }, []);
    return (
        <>
            {modalOpenStatus && element.info && (
                <ProductInfoModal
                    html={element.info}
                    onClose={closeModal}
                    labelCta="MAGGIORI INFORMAZIONI"
                    eventDispatcher={eventDispatcher}
                />
            )}
            {/* DESKTOP */}
            <div className="hidden md:flex md:flex-col md:mb-10">
                <img
                    className="flex cursor-pointer"
                    onClick={() => {
                    }}
                    src={media_base_url + element.image}
                />
                <div className="flex justify-between mt-4">
                    <div className="flex-col max-w-[55%]">
                        <div className="hidden md:flex md:flex-col items-center sm:items-start">
                            <h3 className="m-c-b-18 !text-start m-c-tc-333333">
                                {element.name}
                            </h3>
                            <p className="m-c-r-16 m-c-tc-333333 md:mt-1 lg:mt-2">
                                {element.description}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="hidden md:flex md:items-end md:justify-end xl:min-w-[55%]">
                            <div className="m-c-discount-wrapper">
                                {(element?.bestPrice || 0) > 0 && (
                                    <div className="flex flex-col-reverse md:flex md:flex-row md:items-center">
                                        {element.discountImage && (
                                            <img
                                                className="miglior-prezzo-image mr-3"
                                                src={
                                                    media_base_url +
                                                    element.discountImage
                                                }
                                            />
                                        )}
                                        <div className="flex items-end justify-end">
                                            <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                                {fixToPriceWithoutEuroSimbol(
                                                    element.bestPrice!
                                                )}
                                            </h4>
                                            <h4 className="m-c-b-16-variant m-c-tc-333333  !text-end">
                                                €
                                            </h4>
                                        </div>
                                    </div>
                                )}
                                {(!element.bestPrice ||
                                        element.bestPrice === 0) &&
                                    element.discountPrice && (
                                        <>
                                            <div className="flex items-end">
                                                <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                                    {fixToPriceWithoutEuroSimbol(
                                                        (element?.price || 0)
                                                    )}
                                                </h4>
                                                <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                                    €
                                                </h4>
                                            </div>
                                            <div className="absolute z-50 m-c-discount-line"></div>
                                        </>
                                    )}
                                {(!element.bestPrice ||
                                    element.bestPrice === 0) && (
                                    <div className="flex items-end">
                                        <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                            {element.discountPrice &&
                                            element.discountPrice !== 0
                                                ? fixToPriceWithoutEuroSimbol(
                                                    element.discountPrice
                                                )
                                                : fixToPriceWithoutEuroSimbol(
                                                    element.price!
                                                )}
                                        </h4>
                                        <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className="m-c-r-16 m-c-tc-333333 !text-end md:mt-1 lg:mt-2">
                            {element.sizes}
                        </p>
                    </div>
                </div>
                <div className="flex flex-row-reverse justify-end md:hidden">
                    <div className="m-c-discount-wrapper">
                        {(element?.bestPrice || 0) > 0 && (
                            <div className="flex items-center">
                                <div className="flex items-end">
                                    <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                        {fixToPriceWithoutEuroSimbol(
                                            element.bestPrice!
                                        )}
                                    </h4>
                                    <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                                {element.discountImage && (
                                    <img
                                        className="miglior-prezzo-image ml-3"
                                        src={
                                            media_base_url +
                                            element.discountImage
                                        }
                                    />
                                )}
                            </div>
                        )}
                        {(!element.bestPrice || element.bestPrice === 0) &&
                            element.discountPrice &&
                            element.discountPrice !== 0 && (
                                <>
                                    <div className="">
                                        <h4 className="ml-1.5 m-c-b-22-variant m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.price!
                                            )}
                                        </h4>
                                        <h4 className="ml-1.5 m-c-b-16-variant m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                    <div className="absolute z-50 m-c-discount-line"></div>
                                </>
                            )}
                    </div>
                    {(!element.bestPrice || element.bestPrice === 0) && (
                        <div className="flex items-end">
                            <h4 className="m-c-b-22-variant m-c-tc-333333 !text-start">
                                {element.discountPrice &&
                                element.discountPrice !== 0
                                    ? fixToPriceWithoutEuroSimbol(
                                        element.discountPrice
                                    )
                                    : fixToPriceWithoutEuroSimbol(
                                        element.price!
                                    )}
                            </h4>
                            <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                €
                            </h4>
                        </div>
                    )}
                </div>
                {!!element.info && element.info !== "" && (
                    <p
                        onClick={openModal}
                        className="m-c-r-14 !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                    >
                        MAGGIORI INFORMAZIONI
                    </p>
                )}
                <button
                    className={`m-c-button${
                        addToCartEventPending ? "-disabled" : ""
                    } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF mt-3 !w-full`}
                    disabled={addToCartEventPending}
                    onClick={() => {
                        addToCart(element.sid);
                    }}
                >
                    <div className="flex items-center">
                        <div className="m-c-cart-icon">
                            <svg
                                className="color-white"
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 -960 960 960"
                                width="24"
                            >
                                <path
                                    d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"/>
                            </svg>
                        </div>
                        <div className="m-c-content-wrapper">
                            {selected ? "AGGIUNTO" : "AGGIUNGI AL CARRELLO"}
                        </div>
                    </div>
                </button>
            </div>
            {/* MOBILE */}
            <div className="flex flex-row md:hidden md:mb-10 w-full">
                <div className="w-[50%] flex flex-col">
                    <img
                        className="flex cursor-pointer"
                        onClick={() => {
                            addToCart(element.sid);
                        }}
                        src={media_base_url + element.image}
                    />
                    {!!element.info && (
                        <p
                            onClick={openModal}
                            className="m-c-r-14 !text-[10px] !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                        >
                            MAGGIORI INFORMAZIONI
                        </p>
                    )}
                </div>
                <div className="w-[50%] flex flex-col justify-top relative ml-[14px]">
                    <div className="flex items-center">
                        <h3
                            className="m-c-b-18 w-[80%] m-c-montserrat !text-start m-c-tc-333333"
                            style={{lineHeight: "15px"}}
                        >
                            {element.name}
                        </h3>
                    </div>
                    <p className="m-c-r-16 !text-start mt-[5px]" style={{lineHeight: "13px"}}>
                        {element.description}
                    </p>
                    <p className="m-c-r-16 !text-start mt-[5px]" style={{lineHeight: "13px"}}>
                        {element.sizes}
                    </p>
                    <div className="flex flex-row-reverse justify-end mt-[10px]">
                        <div className="m-c-discount-wrapper">
                            {(element?.bestPrice || 0) > 0 && (
                                <div className="flex flex-col">
                                    {element.discountImage && (
                                        <img
                                            className="miglior-prezzo-image"
                                            src={
                                                media_base_url +
                                                element.discountImage
                                            }
                                        />
                                    )}
                                    <div className="flex items-end mt-3">
                                        <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.bestPrice!
                                            )}
                                        </h4>
                                        <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                </div>
                            )}
                            {(!element.bestPrice || element.bestPrice === 0) &&
                                element.discountPrice &&
                                element.discountPrice &&
                                element.discountPrice !== 0 && (
                                    <>
                                        <div className="">
                                            <h4 className="ml-1.5 m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                                {fixToPriceWithoutEuroSimbol(
                                                    element.price!
                                                )}
                                            </h4>
                                            <h4 className="ml-1.5 m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                                €
                                            </h4>
                                        </div>
                                        <div className="absolute z-50 m-c-discount-line"></div>
                                    </>
                                )}
                        </div>
                        {(!element.bestPrice || element.bestPrice === 0) && (
                            <div className="flex items-end">
                                <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                    {element.discountPrice &&
                                    element.discountPrice !== 0
                                        ? fixToPriceWithoutEuroSimbol(
                                            element.discountPrice
                                        )
                                        : fixToPriceWithoutEuroSimbol(
                                            element.price!
                                        )}
                                </h4>
                                <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                    €
                                </h4>
                            </div>
                        )}
                    </div>

                    <button
                        className={`m-c-button${
                            addToCartEventPending ? "-disabled" : ""
                        } m-c-full-rounded-button m-c-b-16 m-c-tc-FFFFFF mt-3 absolute bottom-[0px] right-[9px]`}
                        disabled={addToCartEventPending}
                        onClick={() => {
                            addToCart(element.sid);
                        }}
                    >
                        <div className="m-c-content-wrapper">
                            {selected && <img src={check}/>}
                            {!selected && <img src={carrello}/>}
                        </div>
                    </button>
                </div>
            </div>
        </>
    );
}
