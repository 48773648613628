import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Element} from "../../utils/models/config.model";
import {fixToPriceWithoutEuroSimbol} from "../../utils/utils";
import ProductInfoModal from "./ProductInfoModal";

export default function ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight({
                                                                                   media_base_url,
                                                                                   element,
                                                                                   currentStepValue,
                                                                                   setCurrentStepValue,
                                                                                   eventDispatcher,
                                                                               }: {
    media_base_url: string;
    element: Element;
    currentStepValue: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    const [modalOpenStatus, setModalOpenStatus] = useState(false);
    const openModal = useCallback(() => {
        setModalOpenStatus(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalOpenStatus(false);
    }, []);
    useEffect(() => {
        if (modalOpenStatus === false) {
            document.body.style.overflow = "unset";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [modalOpenStatus]);
    return (
        <div>
            {modalOpenStatus && element.info && (
                <ProductInfoModal
                    html={element.info}
                    onClose={closeModal}
                    labelCta="MAGGIORI INFORMAZIONI"
                    eventDispatcher={eventDispatcher}
                />
            )}
            <div
                className={`m-c-card-1${
                    currentStepValue === element.id
                        ? " m-c-card-1-selected"
                        : ""
                } flex flex-row md:flex md:flex-col pr-[8px] pt-[10px] pl-[8px] pb-[12px] md:pl-[24px] md:pt-[26px] md:pr-[23px]`}
                onClick={() => {
                    setCurrentStepValue(element.id, element.title);
                }}
            >
                <img
                    className="max-w-[44%] md:max-w-none"
                    src={media_base_url + element.image}
                />
                {/* MOBILE */}
                <div className="flex flex-col md:hidden ml-5 justify-center">
                    <div
                        className={`flex ${
                            element.freeMessage
                                ? "flex-col"
                                : "justify-between items-center"
                        }`}
                    >
                        <div className="flex items-center">
                            <h2 className="m-c-b-21 !text-start m-c-tc-333333">
                                {element.title}
                            </h2>
                        </div>
                    </div>
                    <p className="m-c-r-14 !text-start mt-2">
                        {element.subtitle}
                    </p>
                    {!!element.info && (
                        <p
                            onClick={openModal}
                            className="m-c-r-14 !text-[10px] !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                        >
                            MAGGIORI INFORMAZIONI
                        </p>
                    )}
                    {(!element.freeMessage || element.freeMessage === "") && (
                        <div className="mt-2">
                            {!!element.discountPrice && (
                                <div className="m-c-discount-wrapper mr-2">
                                    <div className="flex items-end">
                                        <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                            {!!element.price
                                                ? fixToPriceWithoutEuroSimbol(
                                                    element.price
                                                )
                                                : null}
                                        </h4>
                                        <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                    <div className="absolute z-50 m-c-discount-line"></div>
                                </div>
                            )}
                            {!!element.price && (
                                <div className="flex items-end">
                                    <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                        +
                                        {!!element.discountPrice
                                            ? fixToPriceWithoutEuroSimbol(
                                                element.discountPrice
                                            )
                                            : fixToPriceWithoutEuroSimbol(
                                                element.price
                                            )}
                                    </h4>
                                    <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className={`md:flex items-end  ${
                            element.freeMessage
                                ? "justify-start"
                                : "justify-end"
                        } mt-2 `}
                    >
                        {element.freeMessage && element.freeMessage !== "" && (
                            <h4 className="m-c-m-14 m-c-tc-333333 !text-start">
                                {element.freeMessage}
                            </h4>
                        )}
                        {element.description && element.description !== "" && (
                            <h4 className="m-c-m-14 m-c-tc-333333 !text-start">
                                {element.description}
                            </h4>
                        )}
                    </div>
                </div>
                {/* DESKTOP */}
                <div className="hidden md:flex md:flex-col justify-between mt-4">
                    <div
                        className={`flex ${
                            element.freeMessage
                                ? "flex-col"
                                : "justify-between items-center"
                        }`}
                    >
                        <div className="flex items-center justify-between">
                            <h2 className="m-c-b-21 !text-start m-c-tc-333333">
                                {element.title}
                            </h2>
                            <div className="flex flex-col xl:max-w-[60%]">
                                {element.freeMessage &&
                                    element.freeMessage !== "" && (
                                        <h4 className="m-c-m-14  m-c-tc-333333 !text-end">
                                            {element.freeMessage}
                                        </h4>
                                    )}
                                {element.description &&
                                    element.description !== "" && (
                                        <h4 className="m-c-m-14 m-c-tc-333333 !text-end">
                                            {element.description}
                                        </h4>
                                    )}
                            </div>
                        </div>
                        <div
                            className={`md:flex items-end  ${
                                element.freeMessage
                                    ? "justify-start"
                                    : "justify-end"
                            } `}
                        >
                            {(!element.freeMessage ||
                                element.freeMessage === "") && (
                                <>
                                    {!!element.discountPrice && (
                                        <div className="m-c-discount-wrapper mr-2">
                                            <div className="flex items-end">
                                                <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                                    {!!element.price
                                                        ? fixToPriceWithoutEuroSimbol(
                                                            element.price
                                                        )
                                                        : null}
                                                </h4>
                                                <h4 className="m-c-b-16-variant m-c-tc-333333 !text-end">
                                                    €
                                                </h4>
                                            </div>
                                            <div className="absolute z-50 m-c-discount-line"></div>
                                        </div>
                                    )}
                                    {!!element.price && (
                                        <div className="flex items-end">
                                            <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                                +
                                                {!!element.discountPrice
                                                    ? fixToPriceWithoutEuroSimbol(
                                                        element.discountPrice
                                                    )
                                                    : fixToPriceWithoutEuroSimbol(
                                                        element.price
                                                    )}
                                            </h4>
                                            <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                                €
                                            </h4>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <p className="m-c-r-14 !text-start mt-2">
                        {element.subtitle}
                    </p>
                    {!!element.info && (
                        <p
                            onClick={openModal}
                            className="m-c-r-14 !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                        >
                            MAGGIORI INFORMAZIONI
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
