import {useQuery} from "@tanstack/react-query";
import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTagManagerInit} from "../hooks/useTagManagerInit";
import {
    WebComponentInnerProps,
    WebComponentProps,
    getConfiguration,
    stepBuilder,
} from "../services/config";
import {
    getStandardEventObj,
    raiseNavigationEvent,
    raiseSelectionEvent,
} from "../services/eventsV2";
import {
    Configurazione,
    Step,
    StepConfigBase,
    StepConfigDefault,
} from "../utils/models/config.model";
import BottomNavigator from "./BottomNavigator";
import {FixedNavigator} from "./FixedNavigator";
import Header from "./Header";
import Skeleton from "./Shared/Skeleton";
import SingleStep from "./SingleStep";
import TopNavigator from "./TopNavigator";
import {ConfiguratorCategoryName} from "../utils/models/configurator.model";
import HeaderWithProgressBar from "./HeaderWithProgressBar";
import {useNavigatorUtil} from "../hooks/useNavigatorUtil";

type EventError = {
    errorCode: string;
    errorMessage: string;
    configurazione?: {
        channel: "ecommerce" | "mobile";
        language: string;
        step: string;
        selected: {
            [key: string]: string;
        };
        products: {
            [key: string]: string;
        };
    };
    props: Omit<
        WebComponentProps,
        | "api_base_url"
        | "media_base_url"
        | "header_type"
        | "ga4_event_identifier"
        | "configurator_main_title"
    >;
};
type EventErrorCallback = {
    success: boolean;
    message: string;
};

export default function Inner({
                                  language,
                                  channel,
                                  startstep,
                                  category,
                                  api_base_url,
                                  media_base_url,
                                  header_type,
                                  ga4_event_identifier,
                                  configurator_main_title,
                                  eventDispatcher,
                              }: WebComponentInnerProps) {
    const [params, setSearchParams] = useSearchParams();
    const [steps, setSteps] = useState<Step[] | undefined>(undefined);
    const currentStepKey = params.get("current_step");

    let configurator_category_name: ConfiguratorCategoryName;

    switch (category) {
        case "blocked-kitchens":
            configurator_category_name = ConfiguratorCategoryName.Cucine;
            break;
        case "sofas":
            configurator_category_name = ConfiguratorCategoryName.Divani;
            break;
        case "mattresses":
            configurator_category_name = ConfiguratorCategoryName.Materassi;
            break;
        default:
            configurator_category_name = ConfiguratorCategoryName.Cucine;
            break;
    }
    const init = useTagManagerInit();
    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            if (!currentStepKey) {
                setSearchParams((lastSteps) => {
                    const newSteps = new URLSearchParams(lastSteps);
                    newSteps.set("current_step", startstep);
                    return newSteps;
                });
            } else {
                /* Preveviene che venga fatto l'init due volte */
                init(); // ToDo: da decommentare
                // raiseEvent({
                //     ...standardEventObj,
                //     action: "start",
                // }, eventDispatcher)
            }
        }
        return () => {
            ignore = true;
        };
    }, [currentStepKey, setSearchParams]);

    const stepToQuery = steps && steps.length > 0 ? currentStepKey : startstep;
    const {data, isLoading, isError, isFetching} = useQuery<
        unknown,
        unknown,
        Configurazione
    >({
        queryKey: ["setup", stepToQuery, language, channel, category],
        enabled: !!stepToQuery,
        queryFn: async () => {
            return getConfiguration({
                category,
                api_base_url,
                body: {
                    channel,
                    language,
                    step: stepToQuery || startstep,
                    ...stepBuilder(stepToQuery as string, steps || [], params),
                },
            });
        },
        keepPreviousData: true,
        cacheTime: 0,
    });
    const currentStepSetup = data?.stepConfig;
    const currentStepExtra = data?.stepExtra;
    const currentSteps = data?.steps;

    useEffect(() => {
        if (data) {
            setSteps(currentSteps);
            /* ref.current?.scrollIntoView({
                behavior: 'smooth'
            }) */
        }
    }, [currentSteps]);

    const paramsCurrentStepValue = currentStepKey
        ? params.get(currentStepKey)
        : null;
    const paramsCurrentStepTitle = currentStepKey
        ? params.get(`${currentStepKey}_title`)
        : null;
    const paramsCurrentStepProduct = currentStepKey
        ? params.get(`${currentStepKey}_products`)
        : null;
    const [localCurrentStepValue, setLocalCurrentStepValue] = useState<
        string | null
    >(paramsCurrentStepValue);
    const [localCurrentStepTitle, setLocalCurrentStepTitle] = useState(
        paramsCurrentStepTitle
    );
    const [localCurrentStepProduct, setLocalCurrentStepProduct] = useState<
        string | null
    >(paramsCurrentStepProduct);
    useEffect(() => {
        setLocalCurrentStepValue(paramsCurrentStepValue);
        setLocalCurrentStepTitle(paramsCurrentStepTitle);
        setLocalCurrentStepProduct(paramsCurrentStepProduct);
    }, [
        currentStepKey,
        paramsCurrentStepValue,
        paramsCurrentStepTitle,
        paramsCurrentStepProduct,
        setLocalCurrentStepValue,
        setLocalCurrentStepTitle,
        setLocalCurrentStepProduct,
    ]);
    const setCurrentStepKey = useCallback(
        (stepKey: string) => {
            setSearchParams((params2) => {
                const newParams = new URLSearchParams(params2);
                const nulledLocalCurrentStepValue =
                    localCurrentStepValue?.length === 0
                        ? null
                        : localCurrentStepValue;
                if (currentStepKey) {
                    // HACK
                    const step = steps?.find(
                        (step) => step.id === currentStepKey
                    ) as unknown as StepConfigBase | undefined;
                    const currentStepIndex = steps!.findIndex(
                        (one) => one.id === currentStepKey
                    );
                    const newStepIndex = steps!.findIndex(
                        (one) => one.id === stepKey
                    );
                    if (steps && step) {
                        // STIAMO ANDANDO AVANTI
                        if (
                            newStepIndex > currentStepIndex &&
                            (!!nulledLocalCurrentStepValue || !step.required)
                        ) {
                            if (localCurrentStepTitle) {
                                newParams.set(
                                    `${currentStepKey}_title`,
                                    localCurrentStepTitle
                                );
                            } else {
                                newParams.delete(`${currentStepKey}_title`);
                            }
                            // è cambiato il valore dello step
                            if (
                                paramsCurrentStepValue !==
                                nulledLocalCurrentStepValue
                            ) {
                                for (
                                    let i = currentStepIndex + 1;
                                    i < steps.length;
                                    i++
                                ) {
                                    const stepId = steps[i].id;
                                    newParams.delete(stepId);
                                    newParams.delete(`${stepId}_products`);
                                    newParams.delete(`${stepId}_title`);
                                }
                                if (nulledLocalCurrentStepValue) {
                                    newParams.set(
                                        currentStepKey,
                                        nulledLocalCurrentStepValue
                                    );
                                } else {
                                    newParams.delete(currentStepKey);
                                }
                                let eventName: string;
                                // LANCIO L'evento per il cambio di step, value, se c'è anche un prodotto il nome deve avere anche _1_
                                if (
                                    (currentStepSetup as StepConfigDefault)
                                        .elements.length > 0 &&
                                    (currentStepSetup as StepConfigDefault)
                                        .products.length > 0
                                ) {
                                    eventName = `${ga4_event_identifier}_s${
                                        currentStepIndex + 1
                                    }_1_${currentStepKey}`;
                                } else {
                                    eventName = `${ga4_event_identifier}_s${
                                        currentStepIndex + 1
                                    }_${currentStepKey}`; //ex. kitchen_configurator_s1_composition
                                }

                                raiseSelectionEvent(
                                    configurator_category_name,
                                    {
                                        action: currentStepKey,
                                        category_name:
                                        configurator_category_name,
                                        label: nulledLocalCurrentStepValue
                                            ? ((
                                                currentStepSetup as StepConfigDefault
                                            ).elements.find(
                                                (el) =>
                                                    el.id ===
                                                    nulledLocalCurrentStepValue
                                            )!.title as unknown as string)
                                            : "Nessuna Selezione",
                                        product_id: null,
                                        product_name: null,
                                        start_point: "menu",
                                    },
                                    eventDispatcher
                                );
                            }

                            // è cambiato il prodotto andando avanti
                            if (
                                localCurrentStepProduct !==
                                paramsCurrentStepProduct
                            ) {
                                // il prodotto è presente
                                if (localCurrentStepProduct) {
                                    // setto e mando evento
                                    raiseSelectionEvent(
                                        configurator_category_name,
                                        {
                                            action: currentStepKey,
                                            category_name:
                                            configurator_category_name,
                                            label: (
                                                currentStepSetup as StepConfigDefault
                                            ).products.find(
                                                (el) =>
                                                    el.sid ===
                                                    localCurrentStepProduct
                                            )!.description,
                                            product_id: null,
                                            product_name: null,
                                            start_point: "menu",
                                        },
                                        eventDispatcher
                                    );
                                    newParams.set(
                                        `${currentStepKey}_products`,
                                        localCurrentStepProduct
                                    );
                                } else {
                                    // tolgo, mando evento? per ora non ci è stato indicato
                                    newParams.delete(
                                        `${currentStepKey}_products`
                                    );
                                }
                            }
                        }
                    }
                    /* Controllo se l'utente ha cliccato avanti. Solo se non ha selezionato nulla.. Quindi la selezione è rimasta invariata.*/
                    //TODO chiedere chiarimenti, corrente o target?
                    raiseNavigationEvent(
                        configurator_category_name,
                        {
                            ...getStandardEventObj(configurator_category_name),
                            action: "navigation",
                            action_type:
                                currentStepIndex > newStepIndex
                                    ? "indietro"
                                    : "avanti",
                            label: currentStepKey,
                        },
                        eventDispatcher
                    );
                }
                if (currentStepKey) {
                    newParams.set("previous_step", currentStepKey);
                }
                newParams.set("current_step", stepKey);
                return newParams;
            });
        },
        [
            currentStepKey,
            steps,
            paramsCurrentStepValue,
            paramsCurrentStepProduct,
            localCurrentStepTitle,
            localCurrentStepValue,
            localCurrentStepProduct,
            eventDispatcher,
            setSearchParams,
        ]
    );

    const setCurrentStepValue = useCallback(
        (
            stepValue: string,
            stepTitle: string,
            product: string | null = null
        ) => {
            setLocalCurrentStepValue(stepValue);
            setLocalCurrentStepTitle(stepTitle);
            setLocalCurrentStepProduct(product);
        },
        []
    );

    const previousStepKey = params.get("previous_step");
    // EFFETTO PER IN SET DEL VALORE DI DEFAULT
    useEffect(() => {
        /* Questo funziona per l'avanti.. Ma anche quando l'utente clicca indietro il sistema in automatico lo rimanda un avanti  */
        if (
            currentStepSetup &&
            currentStepKey &&
            currentStepKey === currentStepSetup.id &&
            currentSteps &&
            currentStepSetup.required &&
            currentStepSetup.skippable &&
            previousStepKey
        ) {
            const currentIndex = currentSteps.findIndex(
                (step) => step.id === currentStepKey
            );
            if (
                currentIndex > 0 &&
                currentSteps[currentIndex - 1].id === previousStepKey
            ) {
                // SIAMO IN UN CASO DI "NEXT"
                if (
                    currentStepSetup.elements.length === 1 &&
                    (!currentStepSetup.products ||
                        currentStepSetup.products.length === 0)
                ) {
                    const [element] = currentStepSetup.elements;
                    setCurrentStepValue(element.id, element.title);
                } else if (
                    currentStepSetup.elements.length === 0 &&
                    currentStepSetup.products.length === 1
                ) {
                    const [product] = currentStepSetup.products;
                    setCurrentStepValue("", product.description, product.sid);
                }
            }
        }
    }, [currentStepSetup, setCurrentStepValue, currentStepKey]);


    const {next} = useNavigatorUtil(currentSteps || [], currentStepSetup, localCurrentStepValue || localCurrentStepProduct, setCurrentStepKey);
    // EFFETTO PER ANDARE AVANTI IN AUTOMATICO
    useEffect(() => {
        /* Questo funziona per l'avanti.. Ma anche quando l'utente clicca indietro il sistema in automatico lo rimanda un avanti  */
        if (
            currentStepSetup &&
            currentSteps &&
            currentStepKey === currentStepSetup.id &&
            currentStepSetup.required &&
            currentStepSetup.skippable &&
            previousStepKey &&
            (localCurrentStepValue || localCurrentStepProduct)
        ) {
            const currentIndex = currentSteps.findIndex(
                (step) => step.id === currentStepKey
            );
            if (
                currentIndex > 0 &&
                currentSteps[currentIndex - 1].id === previousStepKey
            ) {
                // SIAMO IN UN CASO DI "NEXT"
                if (
                    currentStepSetup.elements.length === 1 &&
                    (!currentStepSetup.products ||
                        currentStepSetup.products.length === 0)
                ) {
                    // setCurrentStepKey(currentSteps[currentIndex + 1].id);
                    next()
                } else if (
                    currentStepSetup.elements.length === 0 &&
                    currentStepSetup.products.length === 1
                ) {
                    // setCurrentStepKey(currentSteps[currentIndex + 1].id);
                    next()
                }
            }
        }
    }, [
        currentStepSetup,
        localCurrentStepValue,
        localCurrentStepProduct,
        currentStepKey,
    ]);
    useEffect(() => {
        if (isError) {
            //Resetto i query params e vado al primo passo
            setSearchParams(new URLSearchParams());
            const errorEvent = new CustomEvent<EventError>(
                "decision-tree-error",
                {
                    detail: {
                        errorCode: "b52",
                        errorMessage: "Questo è un errore",
                        configurazione: {
                            channel,
                            language,
                            step: currentStepKey as string,
                            ...stepBuilder(
                                stepToQuery as string,
                                steps || [],
                                params
                            ),
                        },
                        props: {
                            language,
                            channel,
                            startstep,
                            category,
                        },
                    },
                    bubbles: true,
                    cancelable: true,
                    composed: false,
                }
            );
            eventDispatcher(errorEvent);
        }
    }, [isError]);

    const [fixedNavigatorVisibility, setFixedNavigatorVisibility] =
        useState<boolean>(false);
    const [visibilityTopNavigator, setVisibilityTopNavigator] =
        useState<boolean>(false);
    const [visibilityBottomNavigator, setVisibilityBottomNavigator] =
        useState<boolean>(false);
    useEffect(() => {
        if (
            !visibilityTopNavigator &&
            !visibilityBottomNavigator &&
            !fixedNavigatorVisibility
        ) {
            setFixedNavigatorVisibility(true);
        } else if (
            (visibilityBottomNavigator || visibilityTopNavigator) &&
            fixedNavigatorVisibility
        ) {
            setFixedNavigatorVisibility(false);
        }
    }, [
        visibilityTopNavigator,
        visibilityBottomNavigator,
        fixedNavigatorVisibility,
        setVisibilityBottomNavigator,
    ]);
    return (
        <>
            {steps &&
                currentStepSetup &&
                fixedNavigatorVisibility &&
                !isLoading &&
                currentStepKey !== "end" && (
                    <FixedNavigator
                        stepConfig={currentStepSetup}
                        steps={steps}
                        currentStepValue={localCurrentStepValue}
                        setCurrentStepKey={setCurrentStepKey}
                    />
                )}
            <div className="container mx-auto">
                {steps && currentStepSetup && (
                    <div>
                        <div>
                            {(header_type === "normal" || !header_type) && (
                                <Header
                                    configurator_category_name={
                                        configurator_category_name
                                    }
                                    configurator_main_title={
                                        configurator_main_title
                                    }
                                    steps={steps}
                                    currentStep={currentStepSetup}
                                />
                            )}
                            {header_type === "with_progress_bar" && (
                                <HeaderWithProgressBar
                                    configurator_category_name={
                                        configurator_category_name
                                    }
                                    configurator_main_title={
                                        configurator_main_title
                                    }
                                    steps={steps}
                                    currentStep={currentStepSetup}
                                />
                            )}
                            <div
                                className={`mc-container${currentStepSetup.id === "end" ? "-end" : ''} px-8 md:p-0`}
                                id="width-container"
                            >
                                <TopNavigator
                                    configurator_category_name={
                                        configurator_category_name
                                    }
                                    steps={steps}
                                    currentStepValue={localCurrentStepValue}
                                    currentStepSetup={currentStepSetup}
                                    setCurrentStepKey={setCurrentStepKey}
                                    handleVisibilityChange={
                                        setVisibilityTopNavigator
                                    }
                                    eventDispatcher={eventDispatcher}
                                />
                                {isLoading ||
                                    (isFetching && (
                                        <div className="grid grid-cols-2 md:grid-cols-4 gap-[25px] md:gap-8">
                                            <Skeleton/>
                                            <Skeleton/>
                                            <Skeleton/>
                                            <Skeleton/>
                                            <Skeleton className="hidden md:block"/>
                                            <Skeleton className="hidden md:block"/>
                                            <Skeleton className="hidden md:block"/>
                                            <Skeleton className="hidden md:block"/>
                                        </div>
                                    ))}
                                {!isLoading && !isFetching && (
                                    <>
                                        <SingleStep
                                            configurator_category_name={
                                                configurator_category_name
                                            }
                                            steps={steps}
                                            media_base_url={media_base_url}
                                            stepConfig={currentStepSetup}
                                            setCurrentStepKey={
                                                setCurrentStepKey
                                            }
                                            stepExtra={currentStepExtra}
                                            currentStepValue={
                                                localCurrentStepValue
                                            }
                                            currentStepProduct={
                                                localCurrentStepProduct
                                            }
                                            setCurrentStepValue={
                                                setCurrentStepValue
                                            }
                                            eventDispatcher={eventDispatcher}
                                        ></SingleStep>
                                    </>
                                )}
                                {!isLoading && (
                                    <BottomNavigator
                                        configurator_category_name={
                                            configurator_category_name
                                        }
                                        steps={steps}
                                        currentStepValue={localCurrentStepValue}
                                        currentStepSetup={currentStepSetup}
                                        media_base_url={media_base_url}
                                        setCurrentStepKey={setCurrentStepKey}
                                        handleVisibilityChange={(
                                            velue: boolean
                                        ) => {
                                            setVisibilityBottomNavigator(velue);
                                        }}
                                        eventDispatcher={eventDispatcher}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
