import { useCallback, useEffect, useState } from "react";
import { Element } from "../../utils/models/config.model";
import { fixToPriceWithoutEuroSimbol } from "../../utils/utils";
import ProductInfoModal from "./ProductInfoModal";
/* import migliorPrezzoImg from './miglior-prezzo.png'; */

export default function ImageTopTitleSxLeftAndDescriptionOrPriceRight({
    media_base_url,
    element,
    currentStepValue,
    setCurrentStepValue,
    eventDispatcher,
}: {
    media_base_url: string;
    element: Element;
    currentStepValue: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    const [modalOpenStatus, setModalOpenStatus] = useState(false);
    const openModal = useCallback(() => {
        setModalOpenStatus(true);
    }, []);
    const closeModal = useCallback(() => {
        setModalOpenStatus(false);
    }, []);
    useEffect(() => {
        if (modalOpenStatus === false) {
            document.body.style.overflow = "unset";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [modalOpenStatus]);
    return (
        <div>
            {modalOpenStatus && element.info && (
                <ProductInfoModal
                    html={element.info}
                    onClose={closeModal}
                    labelCta="MAGGIORI INFORMAZIONI"
                    eventDispatcher={eventDispatcher}
                />
            )}
            <div
                className={`m-c-card-1${
                    currentStepValue === element.id
                        ? " m-c-card-1-selected"
                        : ""
                } flex flex-row md:flex md:flex-col pr-[9px] pt-[10px] pl-[8px] pb-[12px] md:pl-[24px] md:pt-[26px] md:pr-[23px]`}
                onClick={() => {
                    setCurrentStepValue(element.id, element.title);
                }}
            >
                <img
                    className="max-w-[44%] md:max-w-none"
                    src={media_base_url + element.image}
                />
                {/* MOBILE */}
                <div className="flex flex-col md:hidden justify-center ml-5">
                    <div className="flex items-center justify-between">
                        <h2
                            className="m-c-b-12 m-c-tc-333333 !text-start flex items-center"
                            style={{ maxWidth: "90%" }}
                        >
                            {element.title}
                        </h2>
                    </div>
                    <div className="md:flex items-center mt-2 md:mt-0">
                        {element.bestPrice > 0 && (
                            <div className="flex flex-col">
                                <div className="flex items-end">
                                    <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                        {fixToPriceWithoutEuroSimbol(
                                            element.bestPrice
                                        )}
                                    </h4>
                                    <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                            </div>
                        )}
                        {(!element.bestPrice || element.bestPrice === 0) && (
                            <div className="flex">
                                {!!element.discountPrice && (
                                    <div className="m-c-discount-wrapper mr-2">
                                        <div className="flex items-end">
                                            <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                                {!!element.price
                                                    ? fixToPriceWithoutEuroSimbol(
                                                          element.price
                                                      )
                                                    : null}
                                            </h4>
                                            <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                                €
                                            </h4>
                                        </div>
                                        <div className="absolute z-50 m-c-discount-line"></div>
                                    </div>
                                )}
                                <div className="flex items-end">
                                    <h4 className="m-c-b-21-variant !text-[21px] m-c-tc-333333 !text-start">
                                        {!!element.discountPrice
                                            ? fixToPriceWithoutEuroSimbol(
                                                  element.discountPrice
                                              )
                                            : fixToPriceWithoutEuroSimbol(
                                                  element.price
                                              )}
                                    </h4>
                                    <h4 className="m-c-b-14-variant !text-[14px] m-c-tc-333333 !text-end">
                                        €
                                    </h4>
                                </div>
                            </div>
                        )}
                    </div>
                    {element.discountImage && (
                        <img
                            className="miglior-prezzo-image mt-2"
                            src={media_base_url+element.discountImage}
                        />
                    )}
                </div>
                {/* DESKTOP */}
                <div className="hidden md:flex md:flex-col md:justify-between md:mt-4">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <h2
                                className="m-c-b-14 !text-start m-c-tc-333333"
                                style={{ lineHeight: "10px !important" }}
                            >
                                {element.title}
                            </h2>
                        </div>
                        <div className="md:flex items-center">
                            {element.bestPrice > 0 && (
                                <div className="flex flex-col-reverse xl:flex xl:flex-row xl:items-center">
                                    {element.discountImage && (
                                        <img
                                            className="miglior-prezzo-image lg:!max-h-[22px] lg:mt-1 xl:!max-h-[30px] xl:mt-0 xl:mr-3"
                                            src={media_base_url+element.discountImage}
                                        />
                                    )}
                                    <div className="flex justify-end items-end">
                                        <h4 className="m-c-b-30-variant m-c-tc-333333 !text-start">
                                            {fixToPriceWithoutEuroSimbol(
                                                element.bestPrice
                                            )}
                                        </h4>
                                        <h4 className="m-c-b-22-variant m-c-tc-333333 !text-end" style={{fontSize: "14px !important"}}>
                                            €
                                        </h4>
                                    </div>
                                </div>
                            )}
                            {(!element.bestPrice ||
                                element.bestPrice === 0) && (
                                <div className="flex">
                                    {!!element.discountPrice && (
                                        <div className="m-c-discount-wrapper mr-2">
                                            <div className="flex items-end">
                                                <h4 className="m-c-r-18-variant m-c-tc-333333 !text-start">
                                                    {!!element.price
                                                        ? fixToPriceWithoutEuroSimbol(
                                                              element.price
                                                          )
                                                        : null}
                                                </h4>
                                                <h4 className="ml-1.5 m-c-b-16-variant m-c-tc-333333 !text-end">
                                                    €
                                                </h4>
                                            </div>
                                            <div className="absolute z-50 m-c-discount-line"></div>
                                        </div>
                                    )}
                                    <div className="flex items-end">
                                        <h4 className="m-c-b-30-variant m-c-tc-333333 !text-start">
                                            {!!element.discountPrice
                                                ? fixToPriceWithoutEuroSimbol(
                                                      element.discountPrice
                                                  )
                                                : fixToPriceWithoutEuroSimbol(
                                                      element.price
                                                  )}
                                        </h4>
                                        <h4 className="ml-1.5 m-c-b-22-variant m-c-tc-333333 !text-end">
                                            €
                                        </h4>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <p className="m-c-r-14 !text-start mt-2">
                        {element.subtitle}
                    </p>
                    {!!element.info && (
                        <p
                            onClick={openModal}
                            className="m-c-r-14 !font-bold m-c-tc-DB230B !text-start mt-2 underline cursor-pointer"
                        >
                            MAGGIORI INFORMAZIONI
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
