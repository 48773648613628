import {Element, Step} from "../../utils/models/config.model";

export default function Option({
                                   index,
                                   media_base_url,
                                   element,
                                   currentStepValue,
                                   steps,
                                   step,
                                   setCurrentStepValue,
                                   eventDispatcher,
                               }: {
    index: number;
    media_base_url: string;
    element: Element;
    currentStepValue: string | null;
    steps: Step[];
    step: Step;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    let asterisks = "";
    if (element?.subtitle && element?.subtitle !== "") {
        for (let i = 0; i <= index; i++) {
            asterisks = asterisks + "*";
        }
    }

    return (
        <>
            <div
                className={`m-c-card-option-1${
                    currentStepValue === element.id
                        ? " m-c-card-option-1-selected"
                        : ""
                } flex justify-center items-center p-6 md:p-[26px] min-h-[80px] md:min-h-[120px]`}
                style={{height: "fit-content !important"}}
                onClick={() => {
                    setCurrentStepValue(element.id, element.title);
                }}
            >
                {element.image && (
                    <img
                        className="option-image mr-4"
                        src={media_base_url + element.image}
                    />
                )}
                <h2 className="m-c-b-21 m-c-b-option-title m-c-tc-333333 uppercase !text-start">
                    {element.title}
                    {asterisks}
                </h2>
            </div>
        </>
    );
}
