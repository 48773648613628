import {useContext, useEffect, useRef} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {AppContext} from "../AppContext";
import {getStandardEventObj, raiseEvent} from "../services/eventsV2";
import {Configurazione, Step} from "../utils/models/config.model";
import {ConfiguratorCategoryName} from "../utils/models/configurator.model";

export default function HeaderWithProgressBar({
                                                  configurator_category_name,
                                                  configurator_main_title,
                                                  steps,
                                                  currentStep,
                                              }: {
    configurator_category_name: ConfiguratorCategoryName;
    configurator_main_title: string;
    steps: Step[];
    currentStep: Configurazione["stepConfig"];
}) {
    const {eventDispatcher} = useContext(AppContext)!;
    const [searchParams] = useSearchParams();
    const ref = useRef<HTMLDivElement>(null);
    let currentStepIndex = 0;
    for (let i = 0; i < steps.length; i++) {
        if (steps[i].id === currentStep.id) {
            currentStepIndex = i;
        }
    }
    useEffect(() => {
        ref.current?.scrollIntoView({inline: "center", block: "center"});
    }, [currentStep.id]);


    let progressValue = 0;

    steps.forEach((element, index) => {
        //Non calcolo la percentuale per il primo step
        if (element.id === currentStep.id && index > 0) {
            const current_step_position = index + 1;

            progressValue = (current_step_position * 100) / steps.length;
            progressValue = Math.ceil(progressValue);
        }
    });

    const porgressWith = progressValue;

    const progressCircleDiameter = 35;

    return (
        <>
            <div className="m-c-main-title m-c-b-35 m-c-tc-333333 m-c-t-uppercase !text-start px-8 pt-4 md:!p-0">
                {configurator_main_title}
            </div>
            <div className=" px-8 md:p-0">
                <div className="m-c-progress-bar">
                    <div
                        className="m-c-progress-bar-progress"
                        style={{width: `${porgressWith}%`}}
                    ></div>
                    <div
                        className="m-c-progress-bar-progress-percentage-circle"
                        style={{
                            left: `calc(${progressValue}% - ${progressValue > 0 ? progressCircleDiameter : 0}px)`,
                        }}
                    >
                        <div className="m-c-progress-bar-progress-percentage-circle-text-container">
                            <p>{progressValue}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative" id="m-c-nav-bar-slider">
                <div className="m-c-nav-bar-container !pt-6">
                    {steps.map((el, index) => {
                        const localSearchPrams = new URLSearchParams(
                            searchParams?.toString()
                        );
                        localSearchPrams.set("current_step", el.id);
                        return (
                            <div className="m-c-link-wrapper" key={el.id}>
                                {currentStepIndex > index && (
                                    <Link
                                        className={`m-c-active-bottom-transparent flex flex-col ${index === 0 ? "" : 'items-center'}`}
                                        to={{
                                            search: localSearchPrams?.toString(),
                                        }}
                                        onClick={() => {
                                            raiseEvent(
                                                configurator_category_name,
                                                {
                                                    ...getStandardEventObj(
                                                        configurator_category_name
                                                    ),
                                                    action: "navigation",
                                                    action_type: "navbar",
                                                    label: currentStep.id,
                                                },
                                                eventDispatcher
                                            );
                                        }}
                                    >
                                        <div
                                            className={`m-c-sb-21-header m-c-tc-3E3C3C ${index === 0 ? "sm:!text-start" : 'whitespace-nowrap'}`}
                                        >
                                            {el.label}
                                        </div>
                                        <div
                                            className={`m-c-r-12 m-c-tc-333333 ${index === 0 ? "!text-start" : 'whitespace-nowrap'} mt-[2px] md:mt-[4px]`}>
                                            {searchParams.get(
                                                `${el.id}_title`
                                            ) === null && "Nessuna Selezione"}
                                            {searchParams.get(
                                                    `${el.id}_title`
                                                ) !== null &&
                                                ((
                                                    searchParams.get(
                                                        `${el.id}_title`
                                                    ) as string
                                                )?.length > 20
                                                    ? (
                                                    searchParams.get(
                                                        `${el.id}_title`
                                                    ) as string
                                                ).slice(0, 20) + "..."
                                                    : searchParams.get(
                                                        `${el.id}_title`
                                                    ))}
                                        </div>
                                    </Link>
                                )}
                                {index === currentStepIndex && (
                                    <>
                                        <div
                                            ref={ref}
                                            className="m-c-sb-21-header m-c-tc-3E3C3C m-c-active-bottom-line whitespace-nowrap"
                                            style={{
                                                marginBottom:
                                                    "4px solid #DB230B",
                                            }}
                                        >
                                            {el.label}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
