import {useCallback, useEffect, useRef} from "react";
import {
    Configurazione,
    ResponsiveCols,
    Step,
    StepConfigDefault,
} from "../utils/models/config.model";
import CustomProduct from "./Shared/CustomProduct";
import {SingleElement} from "./Shared/SingleElement";
import Option from "./Shared/Option";

export default function MultiChoiceStep({
                                            steps,
                                            media_base_url,
                                            eventDispatcher,
                                            step,
                                            currentStepValue,
                                            currentStepProduct,
                                            setCurrentStepValue,
                                        }: {
    steps: Step[];

    media_base_url: string;
    step: Configurazione["stepConfig"];
    currentStepValue: string | null;
    currentStepProduct: string | null;
    setCurrentStepValue: (
        stepValue: string,
        stepTitle: string,
        product?: string
    ) => void;
    eventDispatcher: typeof dispatchEvent;
}) {
    const ref = useRef<HTMLDivElement>(null);
    const xlCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.xl;
    const mdCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.md;
    const xsCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.xs;
    const productSelected = !!currentStepProduct;
    const productsEnabled =
        ((step as StepConfigDefault).elements.length === 0 &&
            (step as StepConfigDefault).products) ||
        (step as StepConfigDefault).elements.find(
            (el) => el.id === currentStepValue
        )?.enableProducts;
    const setProductSelected = useCallback(
        (sid: string | null, title?: string) => {
            if (!sid) {
                setCurrentStepValue(
                    currentStepValue || "",
                    "",
                    sid || undefined
                );
            } else {
                setCurrentStepValue(currentStepValue || "", title || "", sid);
            }
        },
        [currentStepValue, setCurrentStepValue, step]
    );
    useEffect(() => {
        if (productsEnabled && !productSelected) {
            ref.current?.scrollIntoView({
                behavior: "smooth",
            });
        } else if (!productsEnabled && productSelected) {
            setProductSelected(null);
        }
    }, [productsEnabled, productSelected]);

    return (
        <section className={`main-section ${step?.boxed ? "main-section-boxed" : ""} `}>
            {/* Caso semplice in cui devo ciclare gli elementi */}
            {!(
                (step as StepConfigDefault).elements.length > 0 &&
                (step as StepConfigDefault).products.length > 0
            ) && (
                <div
                    className={`grid grid-cols-${xsCols} sm:grid-cols-${xsCols} md:grid-cols-${mdCols} xl:grid-cols-${xlCols} gap-[25px] xl:gap-[33px]`}
                >
                    {(step as StepConfigDefault).elements.map(
                        (element) => (
                            <SingleElement
                                media_base_url={media_base_url}
                                key={element.id}
                                template={(step as StepConfigDefault).template}
                                element={element}
                                step={step}
                                currentStepValue={currentStepValue}
                                setCurrentStepValue={setCurrentStepValue}
                                eventDispatcher={eventDispatcher}
                            />
                        )
                    )}
                </div>
            )}
            {(step as StepConfigDefault).elements.length === 0 &&
                (step as StepConfigDefault).products.length === 0 && (
                    <div className="m-c-sb-21 m-c-tc-464545 text-center">
                        OPS... Non ci sono opzioni disponibili.
                    </div>
                )}
            {/* Caso speciale in cui devo ciclare gli elementi come se fossero opzioni */}
            {/* Nel caso in ho sia elementi che prodotti il comportamento è diverso.. Gli elementi in realtà sono delle opzioni che 
    permettono di sloccare la visualizzazione dei prodotti. Posso avere un elemento che si chiama "Predisposta alla lavastoviglie" */}
            {(step as StepConfigDefault).elements.length > 0 &&
                (step as StepConfigDefault).products.length > 0 && (
                    <div
                        className={`grid grid-cols-${step?.boxed === true ? "2" : "1"} md:grid-cols-2 gap-[25px] xl:gap-[33px] `}
                    >
                        {(step as StepConfigDefault).elements.map(
                            (element, index) => (
                                <Option
                                    index={index}
                                    media_base_url={media_base_url}
                                    key={element.id}
                                    element={element}
                                    currentStepValue={currentStepValue}
                                    setCurrentStepValue={setCurrentStepValue}
                                    steps={steps}
                                    step={step}
                                    eventDispatcher={eventDispatcher}
                                ></Option>
                            )
                        )}
                    </div>
                )}
            {(step as StepConfigDefault).elements.length > 0 && (

                <div className="md:flex md:flex-col text-start">
                    {(step as StepConfigDefault).elements.map((el, index) => (
                        <div
                            key={el.id}
                            className={`m-c-l-16 m-c-tc-464545  !text-start ${(index === 0 && (el?.subtitle && el?.subtitle !== "")) ? " mt-10 mb-[26px] md:mt-16" : ""}`}
                        >
                            <b style={{fontWeight: "bold"}}>
                                {(() => {
                                    if (el?.subtitle && el?.subtitle !== "") {
                                        const asterisks = [...Array(index + 1)].map(() => "*");
                                        return asterisks.join("") + el.title;
                                    }
                                })()}

                            </b>
                            &nbsp;{el.subtitle}
                        </div>
                    ))}
                </div>
            )}

            {productsEnabled &&
                (step as StepConfigDefault)?.elements.length !== 0 && (
                    <div ref={ref} className="mt-14 mb-7">
                        <h1 className="m-c-m-25 m-c-tc-333333">
                            {step?.productTitle}
                        </h1>
                    </div>
                )}
            <div
                className={`grid grid-cols-${xsCols} md:grid-cols-${mdCols} xl:grid-cols-${
                    xlCols || 3
                } gap-[25px] xl:gap-[33px]`}
                id="products-list"
            >
                {/* Per mostrare i prodotti devo avere solamente quelli e non avere degli elementi.
            Nel caso in cui ho entrambi allora mostro i prodotti solo nel caso in cui l'elemento selezionato ha la proprietà enableProducts === true*/}
                {productsEnabled &&
                    (step as StepConfigDefault).products.map((element) => (
                        <CustomProduct
                            step={step as StepConfigDefault}
                            media_base_url={media_base_url}
                            key={element.sid}
                            element={element}
                            selected={currentStepProduct === element.sid}
                            setProductSelected={setProductSelected}
                            eventDispatcher={eventDispatcher}
                        />
                    ))}
            </div>
        </section>
    );
}
